import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/components/Home'
import Login from '@/components/Login'
import Accounts from '@/components/Accounts'
import AccountCreate from '@/components/AccountCreate'
import Password from '@/components/Password'
import MoviesAdmin from '@/components/Movies_admin'
import MoviesRegular from '@/components/Movies_regular'
import TVShowsAdmin from '@/components/TVShows_admin'
import TVShowsRegular from '@/components/TVShows_regular'
import LiveTV from '@/components/LiveTV'

Vue.use(VueRouter)

const routes = [
  { path: '/login', component: Login, name: 'login' },
  { path: '/movies/admin', component: MoviesAdmin, name: 'MoviesAdmin' },
  { path: '/movies/regular', component: MoviesRegular, name: 'MoviesRegular' },
  { path: '/tvshows/admin', component: TVShowsAdmin, name: 'TVShowsAdmin' },
  { path: '/tvshows/regular', component: TVShowsRegular, name: 'TVShowsRegular' },
  { path: '/live_tv', component: LiveTV, name: 'LiveTV' },
  { path: '/', component: Home, name: 'Home' },
  { path: '/accounts', component: Accounts },
  { path: '/new_account', component: AccountCreate },
  { path: '/password', component: Password }
]

const router = new VueRouter({
  routes
})

export default router
