<template>
  <div>
    <section>
      <div>
        <vue-masonry-wall
          :items="tvshows"
          :key="filtered_update"
          :options="{width: 300, padding: 12}"
          @append="lazy_load_tvshows">
          <template v-slot:default="{item}">
            <div class="poster-card bm--card-equal-height">
              <div class="card-header">
                <span v-if="item.validated===true">
                  <b-icon
                    pack="fas"
                    icon="check"
                    type="is-success"
                    size="is-small"
                    class="margin-left"/>
                </span>
                <span v-else>
                  <b-icon
                    pack="fas"
                    icon="exclamation-triangle"
                    type="is-danger"
                    size="is-small"
                    class="margin-left"/>
                </span>
              </div>
              <div
                class="card-image"
                @click="tvshow_details(item)">
                <b-image
                  :src="set_image_url(item['poster_path'], item['poster_http_path'])"
                  lazy
                  class="img-container"/>
              </div>
              <div class="card-content">
                <span>{{ title(item.name) }}</span>
              </div>
            </div>
          </template>
        </vue-masonry-wall>
      </div>

      <b-modal
        v-model="tvshow_details_component_active"
        scroll="keep"
        width="70%">
        <div v-if="!watch_content">
          <div
            id="backdrop"
            class="backdrop_image">
            <ResponsiveImage
              :src="set_image_url(selected_item['backdrop_path'], selected_item['backdrop_http_path'])"
              skeleton
              class="backdrop_image"/>
          </div>

          <div
            id="poster_image"
            class="poster_image">
            <ResponsiveImage
              :src="set_image_url(selected_item['poster_path'], selected_item['poster_http_path'])"
              max-width="360px"
              max-height="600px"
              border-radius="15px"
              skeleton/>
          </div>

          <div class="flex-container">
            <div class="flex-child">
              &nbsp;
            </div>
            <div
              id="metadata"
              class="flex-child metadata">

              <b-field
                :label-position="labelPosition"
                label="Title">
                <b-input
                  v-model="selected_item.name"
                  :readonly="true"
                  maxlength="200"
                  type="text"/>
              </b-field>

              <b-field
                :label-position="labelPosition"
                label="Original title">
                <b-input
                  v-model="selected_item['original_name']"
                  :readonly="true"
                  maxlength="200"
                  type="text"/>
              </b-field>

              <b-field
                :label-position="labelPosition"
                label="Synopsis">
                <b-input
                  v-model="selected_item.overview"
                  :readonly="true"
                  maxlength="200"
                  type="textarea"/>
              </b-field>

            </div>
            <div
              id="metadata"
              class="flex-child metadata">

              <b-field
                :label-position="labelPosition"
                label="Year of release">
                <b-input
                  v-model="selected_item.year"
                  :readonly="true"
                  maxlength="200"
                  type="text"/>
              </b-field>

              <b-field
                :label-position="labelPosition"
                label="tmdb-id">
                <b-input
                  v-model="selected_item.id"
                  :readonly="true"
                  maxlength="200"
                  type="text"/>
              </b-field>

              <b-field
                :label-position="labelPosition"
                label="content path">
                <b-input
                  v-model="tvshow_dir"
                  :readonly="true"
                  maxlength="200"
                  type="text"/>
              </b-field>

              <b-field>
                <b-button
                  v-if="youtube_urls"
                  type="is-success is-light"
                  class="margin-left"
                  @click="watchTrailer">
                  Trailer
                </b-button>

                <b-button
                  v-if="youtube_urls"
                  type="is-success is-light"
                  class="margin-left"
                  @click="getEpisodes">
                  Episodes
                </b-button>
              </b-field>
            </div>
          </div>
        </div>
        <div v-else>
          <div v-if="watch_content_youtube">
            <b-field
              grouped
              group-multiline>
              <b-button
                style="margin: 15px"
                @click="watch_content=false; watch_content_youtube=false">
                back
              </b-button>

              <b-dropdown>
                <template #trigger="{ active }">
                  <b-button
                    style="margin-top: 15px;margin-left: 0;margin-bottom: 15px"
                    label="More trailers"/>
                </template>

                <b-dropdown-item
                  v-for="(item, key) in youtube_urls"
                  :key="key"
                  aria-role="listitem"
                  class="text-align-left"
                  @click="youtube_url=item.url">
                  <span>{{ item.name }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </b-field>
            <b-field>
              <LazyYoutubeVideo
                :src="youtube_url"
                preview-image-size="maxresdefault"
                aspect-ratio="16:9"
                autoplay/>
            </b-field>
          </div>
          <div v-else>
            <b-field
              grouped
              group-multiline>
              <b-button
                style="margin: 15px"
                @click="watch_content=false">
                back
              </b-button>
            </b-field>

            <b-tabs v-model="activeTab">
              <b-tab-item
                v-for="(item, key) in seasons_and_episodes"
                :key="key"
                :label="setSeasonLabel(item['season'])">
                <b-table
                  :data="item['episodes']"
                  :show-detail-icon="showDetailIcon"
                  :detail-transition="transitionName"
                  icon-pack="fas"
                  detail-icon="fa-solid fa-chevron-right"
                  striped
                  detailed
                  detail-key="_id"
                  hoverable
                  @click="openSelectedEpisode($event)">
                  <b-table-column
                    v-slot="props"
                    field="episode_number"
                    label="Title"
                    sortable>
                    {{ props.row['episode_number'] }} {{ props.row.name }}
                  </b-table-column>

                  <b-table-column
                    v-slot="props"
                    :numeric="true"
                    field="runtime"
                    label="Runtime"
                    sortable>
                    <div v-if="props.row.runtime===null">
                      Unknown
                    </div>
                    <div v-else>
                      {{ props.row.runtime }}
                    </div>
                  </b-table-column>

                  <template #detail="props">
                    <article class="media">
                      <figure class="media-left">
                        <p class="image is-64x114">
                          <ResponsiveImage
                            :src="set_image_url(props.row['still_path'], null)"/>
                        </p>
                      </figure>
                      <div class="media-content">
                        <div class="content">
                          <p>
                            <strong>{{ props.row.name }}</strong>
                            <br>
                            {{ props.row.overview }}
                          </p>
                        </div>
                      </div>
                    </article>
                  </template>
                </b-table>
              </b-tab-item>
            </b-tabs>
          </div>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>

import VueMasonryWall from 'vue-masonry-wall'
import { Stack, StackItem } from 'vue-stack-grid'
import ResponsiveImage from '@/components/ImageComponed'

export default {
  name: 'TVShows',
  components: { ResponsiveImage, VueMasonryWall, Stack, StackItem },

  data () {
    return {
      activeTab: 0,
      genreFilter: null,
      labelPosition: 'on-border',
      tvshow_details_component_active: false,
      tvshow_dir: '',
      tvshows: [],
      filtered_tvshows: [],
      tvshows_count: 0,
      seasons_and_episodes: [],
      selected_item: {},
      filtered_update: 0,
      showDetailIcon: true,
      toast: '',
      transitionName: 'fade',
      watch_content: false,
      watch_content_youtube: false,
      youtube_url: null,
      youtube_urls: []
    }
  },

  computed: {},

  watch: {
    toast () {
      if (this.toast.method === 'success') {
        this.$toast.success({
          title: this.toast.title,
          message: this.toast.message,
          color: '#798879'
        })
      } else {
        this.$toast.warn({
          title: this.toast.title,
          message: this.toast.message,
          color: '#cc0000'
        })
      }
    },

    tvshow_details_component_active () {
      if (this.tvshow_details_component_active === false) {
        this.watch_content = false
        this.watch_content_youtube = false
        this.youtube_url = null
        this.youtube_urls = []
      }
    },

    selected_item () {
      if (this.selected_item !== {}) {
        this.tvshow_dir = this.selected_item['content_path']['directory'] + '/' + this.selected_item['content_path']['name']
        try {
          for (let i = 0; i < this.selected_item['videos']['results'].length; i++) {
            let url = 'https://www.youtube.com/embed/' + this.selected_item['videos']['results'][i]['key']
            let name = this.selected_item['videos']['results'][i]['name']
            this.youtube_urls.push({url: url, name: name})
          }
          this.youtube_url = this.youtube_urls[0]['url']
        } catch (e) {
          this.youtube_url = null
          this.youtube_urls = []
        }
      }
    },

    '$store.state.genreFilter' () {
      if (this.$store.state.genreFilter === undefined) {
        this.genreFilter = null
        this.tvshows = []
        this.tvshows_count = 0
        this.lazy_load_tvshows(true)
        this.filtered_update += 1
      }
    },

    '$store.state.tvshows_filter' () {
      this.tvshows = []
      this.lazy_load_tvshows(true)
      this.filtered_update += 1
    }
  },

  mounted () {},

  beforeDestroy () {
    this.$store.state.genreFilter = undefined
  },

  created () {
    this.genreFilter = this.$route.params.data
    this.$store.state.genreFilter = this.$route.params.data
  },

  methods: {
    set_image_url: function (identifier, original) {
      if (identifier === undefined) {
        return original
      }
      return '/api/v1/image/get?image_id=' + identifier
    },

    lazy_load_tvshows: function (filter) {
      let params = {match: this.$store.state.tvshows_filter, genre: this.genreFilter}
      if (this.tvshows.length !== 0) {
        params.page_id = this.tvshows[this.tvshows.length - 1]._id
      }
      this.$http.get('/tvshows/get', {params: params})
        .then(response => {
          if (response.data.error) {
            this.errorMessage = response.data.error
          } else {
            this.tvshows = this.tvshows.concat(response.data.result)
            this.tvshows_count = response.data.count
            this.tvshows = [...new Map(this.tvshows.map(item => [item['_id'], item])).values()]
          }
        })
        .catch(_ => {
          console.log(params)
        })
    },

    tvshow_details: function (item) {
      let params = {_id: item._id}
      this.$http.get('/tvshow/get', {params: params})
        .then(response => {
          if (response.data.error) {
            this.errorMessage = response.data.error
          } else {
            this.selected_item = response.data.result
          }
        })
        .catch(_ => {
          console.log(params)
        })
      this.tvshow_details_component_active = true
    },

    getEpisodes: function () {
      let params = {_id: this.selected_item._id}
      this.$http.get('/tvshow/episodes/get', {params: params})
        .then(response => {
          if (response.data.error) {
            this.errorMessage = response.data.error
          } else {
            this.seasons_and_episodes = response.data.result
            this.watch_content = true
          }
        })
        .catch(_ => {
          console.log(params)
        })
    },

    title: function (name) {
      if (name !== undefined) {
        let displayTitle = name.replace(/\n/g, ' ')
        if (displayTitle.length > 31) {
          return displayTitle.substring(0, 31) + '...'
        }
        return displayTitle
      }
    },

    setSeasonLabel: function (label) {
      if (label.toString() === '0') {
        return 'Specials'
      } else {
        return 'Season ' + label.toString()
      }
    },

    watchTrailer: function () {
      this.watch_content = true
      this.watch_content_youtube = true
    },

    openSelectedEpisode: function (event) {
      let title = 'S' + event.season_number + ':E' + event.episode_number + ' ' + event.name
      try {
        let path = event.content_path.directory + '/' + event.content_path.filename
        this.$http.post('/cdn', {filename: path})
          .then(async response => {
            if (response.data.error) {
              this.toast = {
                method: 'warn',
                title: 'Failed getting CDN link',
                message: response.data.error
              }
            } else {
              let dummy = document.createElement('textarea')
              document.body.appendChild(dummy)
              dummy.value = response.data.result
              dummy.focus()
              dummy.select()
              document.execCommand('copy')
              document.body.removeChild(dummy)
              this.toast = {
                method: 'success',
                title: title,
                message: 'You can paste the CDN link in VLC to watch the movie'
              }
            }
          })
          .catch(_ => {
            console.log('Catch: Failed getting CDN link')
          })
      } catch ($e) {
        alert('Cannot copy')
      }
    }
  }
}

</script>

<style scoped>
.text-align-left {
  text-align: left;
}

.is-64x114 {
  width: 114px;
  height: 64px;
}
</style>
