<template>
  <b-navbar
    class="is-spaced has-shadow"
    fixed-top>

    <template slot="brand">
      <b-navbar-item
        :to="{ path: '/' }"
        tag="router-link">
        <img
          alt="Statistics"
          src="@/assets/ankh.png">
      </b-navbar-item>
    </template>

    <template slot="start">
      <div v-if="$store.state.isAdmin">
        <b-navbar-item
          class="is-active margin-left"
          tag="router-link"
          to="/movies/admin">
          <b-icon
            pack="fas"
            icon="film"
            size="is-small"
            class="menu-icon"/>
          Movies
        </b-navbar-item>
      </div>
      <div v-else>
        <b-navbar-item
          class="is-active margin-left"
          tag="router-link"
          to="/movies/regular">
          <b-icon
            pack="fas"
            icon="film"
            size="is-small"
            class="menu-icon"/>
          Movies
        </b-navbar-item>
      </div>
    </template>

    <template slot="start">
      <div v-if="$store.state.isAdmin">
        <b-navbar-item
          class="is-active"
          tag="router-link"
          to="/tvshows/admin">
          <b-icon
            pack="fas"
            icon="tv"
            size="is-small"
            class="menu-icon"/>
          TVShows
        </b-navbar-item>
      </div>
      <div v-else>
        <b-navbar-item
          class="is-active"
          tag="router-link"
          to="/tvshows/regular">
          <b-icon
            pack="fas"
            icon="tv"
            size="is-small"
            class="menu-icon"/>
          TVShows
        </b-navbar-item>
      </div>
    </template>

    <template slot="start">
      <b-navbar-item
        class="is-active"
        tag="router-link"
        to="/live_tv">
        <b-icon
          pack="fas"
          icon="broadcast-tower"
          size="is-small"
          class="menu-icon"/>
        Live TV
      </b-navbar-item>
    </template>

    <!-- Movie search -->

    <template
      v-if="$route.name==='MoviesAdmin' || $route.name==='MoviesRegular'"
      slot="start">
      <b-navbar-item>
        |
      </b-navbar-item>
      <b-input
        v-model="$store.state.movie_filter"
        class="filter"
        maxlength="10"
        type="text"
        placeholder="Movies filter"
        icon-pack="fas"
        icon-right="search"
        icon-right-clickable/>
    </template>

    <!-- TVShow search -->

    <template
      v-if="$route.name==='TVShowsAdmin' || $route.name==='TVShowsRegular'"
      slot="start">
      <b-navbar-item>
        |
      </b-navbar-item>
      <b-input
        v-model="$store.state.tvshows_filter"
        class="filter"
        maxlength="10"
        type="text"
        placeholder="TVShows filter"
        icon-pack="fas"
        icon-right="search"
        icon-right-clickable/>
    </template>

    <!-- Channel search -->

    <template
      v-if="$route.name==='LiveTV'"
      slot="start">
      <b-navbar-item>
        |
      </b-navbar-item>
      <b-input
        v-model="$store.state.channel_filter"
        class="filter"
        maxlength="10"
        type="text"
        placeholder="Channel filter"
        icon-pack="fas"
        icon-right="search"
        icon-right-clickable/>
    </template>

    <!-- BEGIN Scrape buttons BEGIN -->

    <template
      v-if="$store.state.isAdmin && $route.name==='MoviesAdmin'"
      slot="start">
      <b-button
        type="is-success is-light"
        @click="scrape_new_movies">
        Scrape new movies
      </b-button>
    </template>

    <template
      v-if="$store.state.isAdmin && $route.name==='TVShowsAdmin'"
      slot="start">
      <b-button
        type="is-success is-light"
        @click="scrape_new_tvshows">
        Scrape new series
      </b-button>
    </template>

    <!-- END Scrape buttons END-->

    <template
      v-if="$store.state.selected.movies.length !== 0"
      slot="start">
      <b-button
        @click="validate_selected_movies()">
        <b-icon
          pack="fas"
          icon="upload"
          size="is-small"
          class="menu-icon"/>
        Validate movies
      </b-button>
    </template>

    <template
      v-if="$store.state.selected.tvshows.length !== 0"
      slot="start">
      <b-button
        @click="validate_selected_tvshows()">
        <b-icon
          pack="fas"
          icon="upload"
          size="is-small"
          class="menu-icon"/>
        Validate tvshows
      </b-button>
    </template>

    <!-- GENRE FILTERS -->

    <template
      v-if="$store.state.genreFilter !== undefined"
      slot="start">
      <b-icon
        pack="fas"
        icon="filter"
        size="is-small"
        class="menu-icon"/>
      <span class="margin-left">{{ $store.state.genreFilter }}</span>
      <b-button
        type="is-ghost"
        @click="clear_genre_filter">
        <b-icon
          pack="fas"
          icon="backspace"
          size="is-small"
          class="menu-icon btn-link"/>
      </b-button>
    </template>

    <template slot="end">
      <b-navbar-dropdown
        label="User"
        right>
        <span class="user-name">
          <b-icon
            pack="fas"
            icon="user"
            size="is-small"
            class="menu-icon"/>
          Logged in as: <b>{{ $store.state.username }}</b>
        </span>
        <hr>
        <b-navbar-item>
          <div class="external_link">
            <b-icon
              pack="fas"
              icon="code"
              size="is-small"
              class="menu-icon"/>
            <a
              href="/api/v1/apidocs"
              style="color: #4a4a4a;"
              class="btn-link">API docs</a>
          </div>
        </b-navbar-item>
        <b-navbar-item
          v-if="$store.state.isAdmin"
          tag="router-link"
          to="/accounts">
          <b-icon
            pack="fas"
            icon="user-check"
            size="is-small"
            class="menu-icon"/>
          Account Manager
        </b-navbar-item>
        <b-navbar-item
          v-if="$store.state.isAdmin"
          tag="router-link"
          to="/new_account">
          <b-icon
            pack="fas"
            icon="user-plus"
            size="is-small"
            class="menu-icon"/>
          New account
        </b-navbar-item>
        <b-navbar-item
          tag="router-link"
          to="/password">
          <b-icon
            pack="fas"
            icon="user-secret"
            size="is-small"
            class="menu-icon"/>
          Change Password
        </b-navbar-item>
        <b-navbar-item @click.native="logout">
          <b-icon
            pack="fas"
            icon="user-lock"
            size="is-small"
            class="menu-icon"/>
          Logout
        </b-navbar-item>
      </b-navbar-dropdown>
    </template>
  </b-navbar>
</template>

<script>
export default {
  name: 'Navigation',
  data () {
    return {
      has_selected_content: 1,
      toast: {}
    }
  },

  watch: {
    '$store.state.selected.movies': function () {
      // this.has_selected_content++
    },
    toast () {
      if (this.toast.method === 'success') {
        this.$toast.success({
          title: this.toast.title,
          message: this.toast.message
        })
      } else {
        this.$toast.warn({
          title: this.toast.title,
          message: this.toast.message
        })
      }
    }
  },

  methods: {
    logout: function () {
      this.$http.defaults.headers.common['Authorization'] = ''
      this.$store.commit('logout')
      this.$router.push('/login')
    },

    validate_selected_movies: function () {
      for (let i = 0; i < this.$store.state.selected.movies.length; i++) {
        this.$store.state.selected.movies[i].validated = true
      }
      this.$http.post('/movies/patch', this.$store.state.selected.movies)
        .then(response => {
          if (response.data.error) {
            this.toast = {
              method: 'warn',
              title: 'Movie(s) validation failed',
              message: response.data.error
            }
          } else {
            this.toast = {
              method: 'success',
              title: 'Movie(s) validation succeeded',
              message: this.$store.state.selected.movies.length + ' movies validated'
            }
            this.$store.state.selected.movies = []
          }
        })
        .catch(_ => {
          console.log('Catch: Movie(s) validation failed!')
        })
    },

    validate_selected_tvshows: function () {
      for (let i = 0; i < this.$store.state.selected.tvshows.length; i++) {
        this.$store.state.selected.tvshows[i].validated = true
      }
      this.$http.post('/tvshows/patch', this.$store.state.selected.tvshows)
        .then(response => {
          if (response.data.error) {
            this.toast = {
              method: 'warn',
              title: 'TVShow(s) validation failed',
              message: response.data.error
            }
          } else {
            this.toast = {
              method: 'success',
              title: 'TVShow(s) validation succeeded',
              message: this.$store.state.selected.tvshows.length + ' tvshows validated'
            }
            this.$store.state.selected.tvshows = []
          }
        })
        .catch(_ => {
          console.log('Catch: TVShow(s) validation failed!')
        })
    },

    clear_genre_filter: function () {
      this.$store.state.genreFilter = undefined
    },

    scrape_new_movies: function () {
      this.$http.get('/scraper/movies')
        .then(response => {
          if (response.data.error) {
            this.errorMessage = response.data.error
          }
        })
        .catch(_ => {
          console.log('Failed fetching statistics')
        })
    },

    scrape_new_tvshows: function () {
      this.$http.get('/scraper/tvshows')
        .then(response => {
          if (response.data.error) {
            this.errorMessage = response.data.error
          }
        })
        .catch(_ => {
          console.log('Failed fetching statistics')
        })
    }
  }
}
</script>

<style>
.filter {
  margin-bottom: -22px;
  margin-right: 10px;
}

a.navbar-item.is-active.router-link-exact-active.router-link-active {
    background-color: lightgray;
}

.btn-link a{
  text-decoration: none;
}

.menu-icon {
  margin-right: 5px !important;
}

.user-name {
  margin-left: 5px;
  text-align: left;
  float: left;
}

.external_link {
  margin-left: -5px;
}
.margin-left {
  margin-left: 10px;
}
</style>
