import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    selected: {
      movies: [],
      tvshows: []
    },
    movie_filter: '',
    tvshows_filter: '',
    channel_filter: '',
    genreFilter: undefined,
    isLoggedIn: false,
    token: null,
    username: null,
    isAdmin: false
  },
  mutations: {
    initialiseStore (state) {
      if (localStorage.getItem('token')) {
        state.isLoggedIn = false
        state.username = ''
        state.isAdmin = false
      }
    },
    login (state, payload) {
      state.isLoggedIn = true
      state.username = payload.username
      state.isAdmin = payload['isAdmin']
      localStorage.setItem('username', payload.username)
      localStorage.setItem('isAdmin', payload['isAdmin'])
    },
    logout (state) {
      state.isLoggedIn = false
      state.username = ''
      state.isAdmin = false
    }
  }
})

export default store
