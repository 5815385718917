<template>
  <div>
    <section>
      <div>
        <vue-masonry-wall
          :items="tvshows"
          :key="filtered_update"
          :options="{width: 300, padding: 12}"
          @append="lazy_load_tvshows">
          <template v-slot:default="{item}">
            <div class="poster-card bm--card-equal-height">
              <div class="card-header">
                <span v-if="item.validated===true">
                  <b-icon
                    pack="fas"
                    icon="check"
                    type="is-success"
                    size="is-small"
                    class="margin-left"
                    @click="select_item(item)"/>
                </span>
                <span v-else>
                  <span
                    v-if="contains(item)"
                    class="validation">
                    <span
                      @click="select_item(item)">
                      selected for validation
                    </span>
                  </span>
                  <span v-else>
                    <span @click="select_item(item)">
                      <b-icon
                        pack="fas"
                        icon="exclamation-triangle"
                        type="is-danger"
                        size="is-small"
                        class="margin-left"/>
                    </span>
                  </span>
                </span>
              </div>
              <div
                class="card-image"
                @click="tvshow_details(item)">
                <b-image
                  :src="set_image_url(item['poster_path'], item['poster_http_path'])"
                  lazy
                  class="img-container"/>
              </div>
              <div class="card-content">
                <span>{{ title(item.name) }}</span>
              </div>
            </div>
          </template>
        </vue-masonry-wall>
      </div>

      <b-modal
        v-model="tvshow_details_component_active"
        scroll="keep"
        width="70%">
        <div v-if="!search_by_title_active">
          <div v-if="edit_episodes">
            <b-field
              grouped
              group-multiline>
              <b-button
                style="margin: 15px"
                @click="edit_episodes=false">
                back
              </b-button>
            </b-field>
            <b-tabs v-model="activeTab">
              <b-tab-item
                v-for="(item, key) in seasons_and_episodes"
                :key="key"
                :label="setSeasonLabel(item['season'])">
                <b-table
                  :data="item['episodes']"
                  :show-detail-icon="showDetailIcon"
                  :detail-transition="transitionName"
                  icon-pack="fas"
                  detail-icon="fa-solid fa-chevron-right"
                  striped
                  detailed
                  detail-key="_id"
                  hoverable>
                  <b-table-column
                    v-slot="props"
                    field="episode_number"
                    label="Title"
                    sortable>
                    {{ props.row['episode_number'] }} {{ props.row.name }}
                  </b-table-column>

                  <b-table-column
                    v-slot="props"
                    :numeric="true"
                    field="runtime"
                    label="Runtime"
                    width="50"
                    sortable>
                    <b-field>
                      <b-numberinput
                        v-model="props.row.runtime"
                        :controls="false"/>
                    </b-field>
                  </b-table-column>

                  <template #detail="props">
                    <article class="media">
                      <div>
                        <b-field>
                          <figure class="media-left">
                            <div
                              :id="'still-path-' + props.row._id"
                              class="still_path">
                              <p class="image is-64x114">
                                <ResponsiveImage
                                  :key="componentKey"
                                  :src="set_image_url(props.row['still_path'], undefined)"
                                  skeleton
                                  class="still_path"/>
                              </p>
                              <form
                                :id="'form-still-path-' + props.row._id"
                                :class="['still_path_form still_path_upload_box', drag_over_still_path[props.row.id] ? 'still_path_dropbox_active' : '']"
                                @mouseenter="dragging_enter_still_path"
                                @mouseleave="dragging_leave_still_path"
                                @dragleave="dragging_leave_still_path"
                                @dragenter="dragging_enter_still_path"
                                @drop="handleStillPathFileDrop( $event, props.row )">

                                <div
                                  :ref="'still_path_dropbox-' + props.row._id"
                                  class="still_path_upload_text">
                                  Drop replacement image
                                </div>
                              </form>
                            </div>
                          </figure>
                        </b-field>

                        <b-field>
                          <b-button
                            type="is-success is-light"
                            class="is-114"
                            @click="save_episode_details(props.row)">
                            Save
                          </b-button>
                        </b-field>

                        <b-field>
                          <b-button
                            type="is-danger is-light"
                            class="is-114"
                            @click="delete_episode_details(props.row)">
                            Delete
                          </b-button>
                        </b-field>
                      </div>
                      <div class="media-content">
                        <div class="content">
                          <p>
                            <b-field
                              :label-position="labelPosition"
                              label="Title">
                              <b-input v-model="props.row.name"/>
                            </b-field>
                            <br>
                            <b-field
                              :label-position="labelPosition"
                              label="Episode overview">
                              <b-input
                                v-model="props.row.overview"
                                type="textarea"/>
                            </b-field>
                          </p>
                        </div>
                      </div>
                    </article>
                  </template>
                </b-table>
              </b-tab-item>
            </b-tabs>
          </div>
          <div v-else>
            <div
              id="backdrop"
              class="backdrop_image">
              <ResponsiveImage
                :src="set_image_url(selected_item['backdrop_path'], selected_item['backdrop_http_path'])"
                :key="componentKey"
                skeleton
                class="backdrop_image"/>
              <form
                id="backdrop_image_form"
                :class="['backdrop_image_form backdrop_image_upload_box', drag_over_backdrop ? 'backdrop_dropbox_active' : '']"
                @mouseenter="dragging_enter_backdrop"
                @mouseleave="dragging_leave_backdrop"
                @dragleave="dragging_leave_backdrop"
                @dragenter="dragging_enter_backdrop"
                @drop="handleFileDrop( $event, 'backdrop' )">
                <div ref="backdrop_dropbox">
                  <div class="backdrop_image_upload_text">
                    Drop replacement image
                  </div>
                </div>
              </form>
            </div>

            <div
              id="poster_image"
              class="poster_image">
              <ResponsiveImage
                :src="set_image_url(selected_item['poster_path'], selected_item['poster_http_path'])"
                :key="componentKey"
                max-width="360px"
                max-height="600px"
                border-radius="15px"
                skeleton/>
              <form
                id="poster_image_form"
                :class="['poster_image_form poster_image_upload_box', drag_over_poster ? 'poster_dropbox_active' : '']"
                @mouseenter="dragging_enter_poster"
                @mouseleave="dragging_leave_poster"
                @dragleave="dragging_leave_poster"
                @dragenter="dragging_enter_poster"
                @drop.prevent="handleFileDrop( $event, 'poster' )">
                <div ref="poster_dropbox">
                  <div class="poster_image_upload_text">
                    Drop replacement image
                  </div>
                </div>
              </form>
            </div>

            <div class="flex-container">
              <div class="flex-child">
                &nbsp;
              </div>
              <div
                id="metadata"
                class="flex-child metadata">

                <b-field
                  :label-position="labelPosition"
                  label="Title">
                  <b-input
                    v-model="selected_item.name"
                    maxlength="200"
                    type="text"
                    icon-pack="fas"
                    icon-right="search"
                    icon-right-clickable
                    @icon-right-click="search_by_title"/>
                </b-field>

                <b-field
                  :label-position="labelPosition"
                  label="Original title">
                  <b-input
                    v-model="selected_item['original_name']"
                    maxlength="200"
                    type="text"/>
                </b-field>

                <b-field
                  :label-position="labelPosition"
                  label="Synopsis">
                  <b-input
                    v-model="selected_item.overview"
                    maxlength="200"
                    type="textarea"/>
                </b-field>

              </div>
              <div
                id="metadata"
                class="flex-child metadata">

                <b-field
                  :label-position="labelPosition"
                  label="Year of release">
                  <b-input
                    v-model="selected_item.year"
                    maxlength="200"
                    type="text"/>
                </b-field>

                <b-field
                  :label-position="labelPosition"
                  label="tmdb-id">
                  <b-input
                    v-model="selected_item.id"
                    maxlength="200"
                    type="text"
                    icon-pack="fas"
                    icon-right="search"
                    icon-right-clickable
                    @blur="search_by_tmdb_id($event)"/>
                </b-field>

                <b-field
                  :label-position="labelPosition"
                  label="content path">
                  <b-input
                    v-model="tvshow_dir"
                    :readonly="true"
                    maxlength="200"
                    type="text"/>
                </b-field>

                <b-field>
                  <div v-if="must_scrape_tvshow_episodes === false">
                    <b-button
                      type="is-success is-light"
                      class="margin-left"
                      @click="getEpisodes">
                      Episodes
                    </b-button>

                    <b-button
                      type="is-success is-light"
                      class="margin-left"
                      @click="save_tvshow_details">
                      Save
                    </b-button>

                    <b-button
                      type="is-danger is-light"
                      class="margin-left"
                      @click="delete_tvshow_details">
                      Delete
                    </b-button>
                  </div>
                  <div v-else>
                    <b-button
                      type="is-success is-light"
                      class="margin-left"
                      @click="scrape_tvshow_episodes">
                      Get episodes
                    </b-button>

                    <b-button
                      type="is-danger is-light"
                      class="margin-left"
                      @click="tvshow_details_component_active=false">
                      Cancel
                    </b-button>
                  </div>

                </b-field>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <b-field
            grouped
            group-multiline>
            <b-button
              style="margin: 15px"
              @click="search_by_title_active=false">
              back
            </b-button>
          </b-field>
          <b-table
            ref="table"
            :data="search_by_title_results"
            :show-detail-icon="showDetailIcon"
            :opened-detailed="defaultOpenedDetails"
            detail-transition="fade"
            detailed
            hoverable
            selectable
            icon-pack="fas"
            detail-key="id">

            <b-table-column
              v-slot="props"
              label="Title"
              sortable>
              {{ props.row.name }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              label="Year"
              sortable>
              {{ props.row.year }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              label="TMDB Id"
              sortable>
              {{ props.row.id }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              sortable>
              <b-button
                @click="select_tvshow_by_title(props.row.id)">
                select
              </b-button>
            </b-table-column>

            <template #detail="props">
              <article class="media">
                <figure class="media-left">
                  <p class="image is-64x64">
                    <ResponsiveImage :src="props.row['poster_http_path']"/>
                  </p>
                </figure>
                <div class="media-content">
                  <div class="content">
                    <p>
                      <strong>{{ props.row.title }}</strong>
                      <small>&nbsp;original title: {{ props.row['original_name'] }}</small>
                      <br>
                      {{ props.row.overview }}
                    </p>
                  </div>
                </div>
              </article>
            </template>
          </b-table>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>

import VueMasonryWall from 'vue-masonry-wall'
import { Stack, StackItem } from 'vue-stack-grid'
import ResponsiveImage from '@/components/ImageComponed'
import axios from 'axios'

export default {
  name: 'TVShows',
  components: { ResponsiveImage, VueMasonryWall, Stack, StackItem },

  data () {
    return {
      search_by_title_results: [],
      seasons_and_episodes: [],
      selected_item: {},
      tvshows: [],
      tvshows_count: 0,
      tvshow_dir: '',
      filtered_update: 0,

      selected_tmdb_id: null,
      tvshow_details_component_active: false,
      search_by_title_active: false,

      activeTab: 0,
      defaultOpenedDetails: [1],
      edit_episodes: false,
      genreFilter: null,
      labelPosition: 'on-border',
      must_scrape_tvshow_episodes: false,
      showDetailIcon: true,
      toast: '',
      transitionName: 'fade',
      componentKey: 0,

      backdrop_dropbox: false, // dragging over a child element
      poster_dropbox: false, // dragging over a child element
      still_path_dropbox: false,
      dragAndDropCapable: false,
      drag_over_backdrop: false, // dragging over the target OR any child element
      drag_over_poster: false, // dragging over the target OR any child element
      drag_over_still_path: {},
      files: [],
      uploadPercentage: 0,

      youtube_url: null,
      youtube_urls: []
    }
  },

  computed: {},

  watch: {
    toast () {
      if (this.toast.method === 'success') {
        this.$toast.success({
          title: this.toast.title,
          message: this.toast.message,
          color: '#798879'
        })
      } else {
        this.$toast.warn({
          title: this.toast.title,
          message: this.toast.message,
          color: '#cc0000'
        })
      }
    },

    tvshow_details_component_active () {
      if (this.tvshow_details_component_active === false) {
        this.watch_content = false
        this.watch_content_youtube = false
        this.youtube_url = null
        this.youtube_urls = []
        this.must_scrape_tvshow_episodes = false
        this.selected_tmdb_id = null
        this.edit_episodes = false
      }
    },

    selected_item () {
      this.tvshow_dir = this.selected_item['content_path']['directory'] + '/' + this.selected_item['content_path']['name']
    },

    '$store.state.tvshows_filter' () {
      this.tvshows = []
      this.lazy_load_tvshows()
      this.filtered_update += 1
    }
  },

  mounted () {},

  beforeDestroy () {
    this.$store.state.genreFilter = undefined
  },

  created () {
    this.genreFilter = this.$route.params.data
    this.$store.state.genreFilter = this.$route.params.data
  },

  methods: {
    set_image_url: function (identifier, original) {
      if (identifier === undefined) {
        return original
      }
      return '/api/v1/image/get?image_id=' + identifier
    },

    lazy_load_tvshows: function () {
      let params = {match: this.$store.state.tvshows_filter}
      if (this.tvshows.length !== 0) {
        params.page_id = this.tvshows[this.tvshows.length - 1]._id
      }
      this.$http.get('/tvshows/get', {params: params})
        .then(response => {
          if (response.data.error) {
            this.errorMessage = response.data.error
          } else {
            this.tvshows = this.tvshows.concat(response.data.result)
            this.tvshows_count = response.data.count
            this.tvshows = [...new Map(this.tvshows.map(item => [item['_id'], item])).values()]
          }
        })
        .catch(_ => {
          console.log(params)
        })
    },

    tvshow_details: function (item) {
      let params = {_id: item._id}
      this.$http.get('/tvshow/get', {params: params})
        .then(response => {
          if (response.data.error) {
            this.errorMessage = response.data.error
          } else {
            this.selected_item = response.data.result
            this.selected_tmdb_id = this.selected_item.id
          }
        })
        .catch(_ => {
          console.log(params)
        })
      this.tvshow_details_component_active = true
    },

    getEpisodes: function () {
      let params = {_id: this.selected_item._id}
      this.$http.get('/tvshow/episodes/get', {params: params})
        .then(response => {
          if (response.data.error) {
            this.errorMessage = response.data.error
          } else {
            this.seasons_and_episodes = response.data.result
            this.edit_episodes = true
          }
        })
        .catch(_ => {
          console.log(params)
        })
    },

    title: function (name) {
      if (name !== undefined) {
        let displayTitle = name.replace(/\n/g, ' ')
        if (displayTitle.length > 31) {
          return displayTitle.substring(0, 31) + '...'
        }
        return displayTitle
      }
    },

    setSeasonLabel: function (label) {
      if (label.toString() === '0') {
        return 'Specials'
      } else {
        return 'Season ' + label.toString()
      }
    },

    select_item: function (item) {
      if (this.contains(item)) {
        this.$store.state.selected.tvshows = this.$store.state.selected.tvshows.filter(_ => _._id !== item._id)
        console.log('Un-selected ' + item.title + ' for validation approval')
      } else {
        this.$store.state.selected.tvshows.push(item)
        console.log('Selected ' + item.title + ' for validation approval')
      }
    },

    contains: function (item) {
      for (let i = 0; i < this.$store.state.selected.tvshows.length; i++) {
        if (this.$store.state.selected.tvshows[i]._id === item._id) {
          return true
        }
      }
      return false
    },

    save_tvshow_details: function () {
      this.selected_item.id = parseInt(this.selected_item.id)
      this.$http.patch('/tvshow/patch', this.selected_item)
        .then(response => {
          if (response.data.error) {
            this.toast = {
              method: 'warn',
              title: 'Update failed',
              message: response.data.error
            }
          } else {
            for (let i = 0; i < this.tvshows.length; i++) {
              if (this.tvshows[i]._id === this.selected_item._id) {
                this.tvshows[i] = JSON.parse(JSON.stringify(this.selected_item))
              }
            }
            this.toast = {
              method: 'success',
              title: 'Updated tvshow',
              message: this.selected_item.title
            }
            this.selected_item = {}
          }
        })
        .catch(_ => {
          console.log('Catch: update failed!')
        })
      this.tvshow_details_component_active = false
    },

    delete_tvshow_details: function () {
      let data = {_id: this.selected_item._id}
      this.$http.delete('/tvshow/delete', {data: data})
        .then(response => {
          if (response.data.error) {
            this.toast = {
              method: 'warn',
              title: 'Delete failed',
              message: response.data.error
            }
          } else {
            this.tvshows = this.tvshows.filter(_ => _._id !== this.selected_item._id)
            this.toast = {
              method: 'success',
              title: 'Deleted tvshow',
              message: this.selected_item.title
            }
            this.selected_item = {}
          }
        })
        .catch(_ => {
          console.log(data)
        })
      this.tvshow_details_component_active = false
    },

    search_by_title: function () {
      let params = {'name': this.selected_item.name}
      this.$http.get('/scraper/tvshow/by_title', {params: params})
        .then(response => {
          if (response.data.error) {
            this.toast = {
              method: 'warn',
              title: 'Search by title failed',
              message: response.data.error
            }
          } else {
            if (response.data.result.length === 0) {
              this.toast = {
                method: 'warn',
                title: 'Searching by title',
                message: 'No results found for ' + this.selected_item.name
              }
            } else {
              this.toast = {
                method: 'success',
                title: 'Searching by title',
                message: 'Found ' + response.data.result.length + ' titles'
              }
              this.search_by_title_results = response.data.result
              this.search_by_title_active = true
            }
          }
        })
        .catch(_ => {
          console.log('Catch: Search failed!')
        })
    },

    search_by_tmdb_id: function () {
      if (this.selected_tmdb_id !== this.selected_item.id) {
        this.$http.post('/scraper/tvshow/by_id', {tmdb_id: parseInt(this.selected_item.id)})
          .then(response => {
            if (response.data.error) {
              this.toast = {
                method: 'warn',
                title: 'Search by tmdb-id failed',
                message: response.data.error
              }
            } else {
              let _id = this.selected_item['_id']
              let contentPath = this.selected_item['content_path']
              this.selected_item = response.data.result
              this.selected_item['_id'] = _id
              this.selected_item['content_path'] = contentPath
              this.toast = {
                method: 'success',
                title: 'Searching by TMDB-id',
                message: this.selected_item.id
              }
              this.selected_tmdb_id = this.selected_item.id
              this.must_scrape_tvshow_episodes = true
            }
          })
          .catch(_ => {
            console.log('Catch: Search failed!')
          })
      }
    },

    scrape_tvshow_episodes: function () {
      let params = {
        tmdb_id: parseInt(this.selected_item.id),
        _id: this.selected_item._id,
        name: this.selected_item.name,
        content_path: this.selected_item.content_path
      }

      this.$http.post('/scraper/tvshow/episodes', params)
        .then(response => {
          if (response.data.error) {
            this.toast = {
              method: 'warn',
              title: 'Scraping episodes failed',
              message: response.data.error
            }
          } else {
            this.toast = {
              method: 'success',
              title: 'Scraping episodes',
              message: response.data.result
            }
            this.must_scrape_tvshow_episodes = false
            this.save_tvshow_details()
            this.tvshow_details_component_active = false
          }
        })
        .catch(_ => {
          console.log('Catch: Scraping episodes failed!')
        })
    },

    select_tvshow_by_title: function (id) {
      this.$http.post('/scraper/tvshow/by_id', {tmdb_id: parseInt(id)})
        .then(response => {
          if (response.data.error) {
            this.toast = {
              method: 'warn',
              title: 'Search by tmdb-id failed',
              message: response.data.error
            }
          } else {
            let _id = this.selected_item['_id']
            let contentPath = this.selected_item['content_path']
            this.selected_item = response.data.result
            this.selected_item['_id'] = _id
            this.selected_item['content_path'] = contentPath
            this.toast = {
              method: 'success',
              title: 'Searching by TMDB-id',
              message: this.selected_item.id
            }
            this.scrape_tvshow_episodes()
            this.search_by_title_active = false
          }
        })
        .catch(_ => {
          console.log('Catch: Search failed!')
        })
    },

    save_episode_details (episode) {
      this.$http.patch('/tvshow/episode/patch', episode)
        .then(response => {
          if (response.data.error) {
            this.toast = {
              method: 'warn',
              title: 'Update failed',
              message: response.data.error
            }
          } else {
            this.toast = {
              method: 'success',
              title: 'Updated tvshow episode',
              message: episode.name
            }
          }
        })
        .catch(_ => {
          console.log('Catch: update failed!')
        })
    },

    delete_episode_details (episode) {
      let data = {_id: episode._id}
      this.$http.delete('/tvshow/episode/delete', {data: data})
        .then(response => {
          if (response.data.error) {
            this.toast = {
              method: 'warn',
              title: 'Delete failed',
              message: response.data.error
            }
          } else {
            for (let i = 0; i < this.seasons_and_episodes.length; i++) {
              if (this.seasons_and_episodes[i].season === episode['season_number']) {
                this.seasons_and_episodes[i].episodes = this.seasons_and_episodes[i].episodes.filter(_ => _._id !== episode._id)
                this.toast = {
                  method: 'success',
                  title: 'Deleted tvshow episode',
                  message: episode.name
                }
                return
              }
            }
          }
        })
        .catch(_ => {
          console.log('Catch: update failed!')
        })
    },

    dragging_enter_poster: function (event) {
      if (event.target === this.$refs.poster_dropbox) this.poster_dropbox = true
      else this.drag_over_poster = true
      this.bindEvents()
    },

    dragging_leave_poster: function (event) {
      if (event.target === this.$refs.poster_dropbox) this.poster_dropbox = false
      else this.drag_over_poster = false
      this.bindEvents()
    },

    dragging_enter_backdrop: function (event) {
      if (event.target === this.$refs.backdrop_dropbox) this.backdrop_dropbox = true
      else this.drag_over_backdrop = true
      this.bindEvents()
    },

    dragging_leave_backdrop: function (event) {
      if (event.target === this.$refs.backdrop_dropbox) this.backdrop_dropbox = false
      else this.drag_over_backdrop = false
      this.bindEvents()
    },

    dragging_enter_still_path: function (event) {
      let eventTarget = event.target.parentNode.id
      let refs = 'still-path-' + eventTarget

      if (event.target === this.$refs[refs]) this.still_path_dropbox = true
      else this.drag_over_still_path[eventTarget] = true
      this.bindEventsStillPath(event, eventTarget)
    },

    dragging_leave_still_path: function (event) {
      let eventTarget = event.target.parentNode.id
      let refs = 'still-path-' + eventTarget

      if (event.target === this.$refs[refs]) this.still_path_dropbox = false
      else this.drag_over_still_path[eventTarget] = false
      this.bindEventsStillPath(event, eventTarget)
    },

    bindEventsStillPath: function (event, eventTarget) {
      // Enable drag drop event-handlers
      let stringArray = eventTarget.split('-')
      let objectId = stringArray[stringArray.length - 1]

      let formId = 'form-still-path-' + objectId
      this.dragAndDropCapable = this.determineDragAndDropCapable()
      if (this.dragAndDropCapable) {
        ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(function (evt) {
          try {
            document.getElementById(formId).addEventListener(evt, function (e) {
              e.preventDefault()
              e.stopPropagation()
            }, false)
          } catch (error) {
            console.log('bindEventsStillPath: ', objectId, ' evt: ', event.type, ' target: ', event.target, ' error: ', error)
          }
        })
      }
    },

    bindEvents: function () {
      // Enable drag drop event-handlers
      this.dragAndDropCapable = this.determineDragAndDropCapable()
      if (this.dragAndDropCapable) {
        ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(function (evt) {
          document.getElementById('poster_image_form').addEventListener(evt, function (e) {
            e.preventDefault()
            e.stopPropagation()
          }, false)
          document.getElementById('backdrop_image_form').addEventListener(evt, function (e) {
            e.preventDefault()
            e.stopPropagation()
          }, false)
        })
      }
    },

    handleStillPathFileDrop (event, episode) {
      for (let i = 0; i < event.dataTransfer.files.length; i++) {
        this.files.push(event.dataTransfer.files[i])
      }
      this.submitStillPathFiles(episode)
    },

    handleFileDrop (event, type) {
      console.log('Uploading ' + type + ' image')
      for (let i = 0; i < event.dataTransfer.files.length; i++) {
        this.files.push(event.dataTransfer.files[i])
      }
      this.submitFiles(type)
    },

    determineDragAndDropCapable () {
      let div = document.createElement('div')
      return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window
    },

    submitFiles (type) {
      let formData = new FormData()
      formData.append('type', type)
      formData.append('tvshow_id', this.selected_item['_id'])
      if (type === 'poster') {
        formData.append('image_id', this.selected_item['poster_path'])
        formData.append('path', 'poster_path')
      } else {
        formData.append('image_id', this.selected_item['backdrop_path'])
        formData.append('path', 'backdrop_path')
      }
      formData.append('data', this.files[0])

      axios.post('/tvshows/image/post',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
          }.bind(this)
        }
      ).then((response) => {
        this.selected_item = response.data.result
        for (let i = 0; i < this.tvshows.length; i++) {
          if (this.tvshows[i]._id === this.selected_item._id) {
            this.tvshows[i] = JSON.parse(JSON.stringify(this.selected_item))
          }
        }
        this.componentKey += 1
        this.files = []
        console.log('SUCCESS!!')
      })
        .catch((response) => {
          this.files = []
          console.log('FAILURE!!')
        })
    },

    submitStillPathFiles (episode) {
      let formData = new FormData()
      formData.append('_id', episode._id)
      formData.append('image_id', episode['still_path'])
      formData.append('path', 'still_path')
      formData.append('data', this.files[0])

      axios.post('/tvshows/episode/image/post',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
          }.bind(this)
        }
      ).then((response) => {
        this.files = []

        let episode = response.data.result
        for (let i = 0; i < this.seasons_and_episodes.length; i++) {
          if (this.seasons_and_episodes[i]['season'] === episode['season_number']) {
            for (let j = 0; j < this.seasons_and_episodes[i].episodes.length; j++) {
              if (this.seasons_and_episodes[i].episodes[j]['_id'] === episode._id) {
                this.seasons_and_episodes[i].episodes[j] = episode
                this.componentKey += 1
                this.toast = {
                  method: 'success',
                  title: 'Still image uploaded',
                  message: episode.name
                }
              }
            }
          }
        }
      })
        .catch((response) => {
          this.files = []
          console.log('FAILURE!!')
        })
    }
  }
}

</script>

<style scoped>
.text-align-left {
  text-align: left;
}

.is-64x114 {
  width: 114px;
  height: 64px;
}

.is-114 {
  width: 114px;
  top: 64px;
}

.backdrop_image {
  position: relative;
  width: auto;
  height: 440px;
}

.backdrop_image_form {
  position: relative;
  width: auto;
  height: 440px;
}

.backdrop_image_upload_box {
  position:absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 440px;
}

.backdrop_image_upload_box:hover,
.backdrop_image_upload_box.backdrop_dropbox_active {
  visibility: visible;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.8);
  color: cyan;
  font-size: large;
}

.backdrop_image_upload_text {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 65%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  font-size: large;
  padding: 16px 32px;
  color: cyan;
}

.backdrop_image_upload_box:hover .backdrop_image_upload_text {
  opacity: 1;
}

.backdrop_image_upload_box.backdrop_dropbox_active .backdrop_image_upload_text {
  opacity: 1;
}

.poster_image {
  top: 140px;
  left: 40px;
  width: 360px;
  height: 540px;
  position: absolute;
  background-color: #dbdbdb;
  border-color: #2c3e50;
  border-radius: 15px;
  border-width: 3px;
}

.poster_image_form {
  top: 0;
  left: 0;
  width: 360px;
  height: 540px;
  position: absolute;
}

.poster_image_upload_box {
  position:absolute;
  top:0;
  left:0;
  width:360px;
  height:540px;
}

.poster_image_upload_box:hover,
.poster_image_upload_box.poster_dropbox_active {
  visibility: visible;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.8);
  color: cyan;
  font-size: large;
}

.poster_image_upload_text {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  font-size: large;
  padding: 16px 32px;
  color: cyan;
}

.poster_image_upload_box:hover .poster_image_upload_text {
  opacity: 1;
}

.poster_image_upload_box.poster_dropbox_active .poster_image_upload_text {
  opacity: 1;
}

.still_path {
  width: 114px;
  height: 64px;
  position: absolute;
  background-color: #dbdbdb;
  border-color: #2c3e50;
}

.still_path_form {
  top: 0;
  left: 0;
  width: 114px;
  height: 64px;
  position: absolute;
}

.still_path_upload_box {
  position:absolute;
  top:0;
  left:0;
  width: 114px;
  height: 64px;
}

.still_path_upload_box:hover,
.still_path_upload_box.still_path_dropbox_active {
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.8);
  color: cyan;
  font-size: large;
}

.still_path_upload_text {
  transition: .5s ease;
  opacity: 0;
  text-align: center;
  font-size: small;
  color: cyan;
  padding-top: 10px;
}

.still_path_upload_box:hover .still_path_upload_text {
  opacity: 1;
}

.still_path_upload_box.still_path_dropbox_active .still_path_upload_text {
  opacity: 1;
}

</style>
