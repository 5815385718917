<template>
  <div>
    <section>
      <div>
        <vue-masonry-wall
          :items="movies"
          :key="filtered_update"
          :options="{width: 300, padding: 12}"
          @append="lazy_load_movies">
          <template v-slot:default="{item}">
            <div class="poster-card bm--card-equal-height">
              <div class="card-header">
                <span v-if="item.validated===true">
                  <b-icon
                    pack="fas"
                    icon="check"
                    type="is-success"
                    size="is-small"
                    class="margin-left"/>
                </span>
                <span v-else>
                  <b-icon
                    pack="fas"
                    icon="exclamation-triangle"
                    type="is-danger"
                    size="is-small"
                    class="margin-left"/>
                </span>
              </div>
              <div
                v-if="mobile_view===false"
                class="card-image"
                @click="movie_details(item)">
                <b-image
                  :src="set_image_url(item['poster_path'], item['poster_http_path'])"
                  lazy
                  class="img-container"/>
              </div>
              <div
                v-else
                class="card-image"
                @click="toClipboard(item['cdn_link'])">
                <b-image
                  :src="set_image_url(item['poster_path'], item['poster_http_path'])"
                  lazy
                  class="img-container"/>
              </div>
              <div class="card-content">
                <span :id="item._id">{{ title(item.title) }}</span>
              </div>
            </div>
          </template>
        </vue-masonry-wall>
      </div>

      <b-modal
        v-model="movie_details_component_active"
        scroll="keep"
        width="70%">
        <div v-if="!watch_content">
          <div
            id="backdrop"
            class="backdrop_image">
            <ResponsiveImage
              :src="set_image_url(selected_item['backdrop_path'], selected_item['backdrop_http_path'])"
              skeleton
              class="backdrop_image"/>
          </div>

          <div
            id="poster_image"
            class="poster_image">
            <ResponsiveImage
              :src="set_image_url(selected_item['poster_path'], selected_item['poster_http_path'])"
              max-width="360px"
              max-height="600px"
              border-radius="15px"
              skeleton/>
          </div>

          <div class="flex-container">
            <div class="flex-child">
              &nbsp;
            </div>
            <div
              id="metadata"
              class="flex-child metadata">

              <b-field
                :label-position="labelPosition"
                label="Title">
                <b-input
                  v-model="selected_item.title"
                  :readonly="true"
                  maxlength="200"
                  type="text"/>
              </b-field>

              <b-field
                :label-position="labelPosition"
                label="Original title">
                <b-input
                  v-model="selected_item['original_title']"
                  :readonly="true"
                  maxlength="200"
                  type="text"/>
              </b-field>

              <b-field
                :label-position="labelPosition"
                label="Synopsis">
                <b-input
                  v-model="selected_item.overview"
                  :readonly="true"
                  maxlength="200"
                  type="textarea"/>
              </b-field>

            </div>
            <div
              id="metadata"
              class="flex-child metadata">

              <b-field
                :label-position="labelPosition"
                label="Year of release">
                <b-input
                  v-model="selected_item.year"
                  :readonly="true"
                  maxlength="200"
                  type="text"/>
              </b-field>

              <b-field
                :label-position="labelPosition"
                label="tmdb-id">
                <b-input
                  v-model="selected_item.id"
                  :readonly="true"
                  maxlength="200"
                  type="text"/>
              </b-field>

              <b-field
                :label-position="labelPosition"
                label="content path">
                <b-input
                  v-model="movie_file"
                  :readonly="true"
                  maxlength="200"
                  type="text"/>
              </b-field>

              <b-field>
                <b-button
                  v-if="youtube_url"
                  type="is-success is-light"
                  class="margin-left"
                  @click="watchTrailer">
                  Trailer
                </b-button>
                <b-button
                  type="is-success is-light"
                  class="margin-left"
                  @click="get_cdn_link">
                  Movie
                </b-button>
              </b-field>
            </div>
          </div>
        </div>
        <div v-else>
          <b-field
            grouped
            group-multiline>
            <b-button
              style="margin: 15px"
              @click="watch_content=false">
              back
            </b-button>

            <b-dropdown>
              <template #trigger="{ active }">
                <b-button
                  style="margin-top: 15px;margin-left: 0;margin-bottom: 15px"
                  label="More trailers"/>
              </template>

              <b-dropdown-item
                v-for="(item, key) in youtube_urls"
                :key="key"
                aria-role="listitem"
                class="text-align-left"
                @click="youtube_url=item.url">
                <span>{{ item.name }}</span>
              </b-dropdown-item>
          </b-dropdown></b-field>
          <b-field>
            <LazyYoutubeVideo
              :src="youtube_url"
              preview-image-size="maxresdefault"
              aspect-ratio="16:9"
              autoplay/>
          </b-field>
        </div>
      </b-modal>

    </section>
  </div>
</template>

<script>
import VueMasonryWall from 'vue-masonry-wall'
import {Stack, StackItem} from 'vue-stack-grid'
import ResponsiveImage from '@/components/ImageComponed'

export default {
  components: { ResponsiveImage, VueMasonryWall, Stack, StackItem },

  data () {
    return {
      // Data structures
      movies: [],
      movies_count: 0,
      selected_item: {},
      filtered_update: 0,

      // Variables
      genreFilter: null,
      toast: '',
      movie_file: '',
      watch_content: false,
      youtube_url: null,
      youtube_urls: [],
      showDetailIcon: true,
      defaultOpenedDetails: [1],
      search_by_title_active: false,
      movie_details_component_active: false,
      labelPosition: 'on-border',
      mobile_view: false,
      content_link: ''
    }
  },

  computed: {},

  watch: {
    toast () {
      if (this.toast.method === 'success') {
        this.$toast.success({
          title: this.toast.title,
          message: this.toast.message,
          color: '#798879'
        })
      } else {
        this.$toast.warn({
          title: this.toast.title,
          message: this.toast.message,
          color: '#cc0000'
        })
      }
    },

    movie_details_component_active () {
      if (this.movie_details_component_active === false) {
        this.watch_content = false
        this.youtube_url = null
        this.youtube_urls = []
      }
    },

    selected_item () {
      this.movie_file = this.selected_item['content_path']['directory'] + '/' + this.selected_item['content_path']['filename']
      try {
        for (let i = 0; i < this.selected_item['videos']['results'].length; i++) {
          let url = 'https://www.youtube.com/embed/' + this.selected_item['videos']['results'][i]['key']
          let name = this.selected_item['videos']['results'][i]['name']
          this.youtube_urls.push({url: url, name: name})
        }
        this.youtube_url = this.youtube_urls[0]['url']
      } catch (e) {
        this.youtube_url = null
        this.youtube_urls = []
      }
    },

    '$store.state.genreFilter' () {
      if (this.$store.state.genreFilter === undefined) {
        this.genreFilter = null
        this.movies = []
        this.movies_count = 0
        this.lazy_load_movies()
      }
    },

    '$store.state.movie_filter' () {
      this.movies = []
      this.lazy_load_movies()
      this.filtered_update += 1
    },

    content_link () {
      this.toClipboard(this.content_link)
    }
  },

  mounted () {},

  beforeDestroy () {
    this.$store.state.genreFilter = undefined
  },

  created () {
    this.mobile_view = this.$isMobile()
    this.genreFilter = this.$route.params.data
    this.$store.state.genreFilter = this.$route.params.data
    this.lazy_load_movies()
  },

  methods: {
    set_image_url: function (identifier, original) {
      if (identifier === undefined) {
        return original
      }
      return '/api/v1/image/get?image_id=' + identifier
    },

    lazy_load_movies: function () {
      let params = {match: this.$store.state.movie_filter, genre: this.genreFilter}
      if (this.movies.length !== 0) {
        params.page_id = this.movies[this.movies.length - 1]._id
      }
      this.$http.get('/movies/get', {params: params})
        .then(response => {
          if (response.data.error) {
            this.errorMessage = response.data.error
          } else {
            this.movies = this.movies.concat(response.data.result)
            this.movies = [...new Map(this.movies.map(item => [item['_id'], item])).values()]
            this.movies_count = response.data.count
          }
        })
        .catch(_ => {
          console.log(params)
        })
    },

    title: function (title) {
      if (title !== undefined) {
        let displayTitle = title.replace(/\n/g, ' ')
        if (displayTitle.length > 31) {
          return displayTitle.substring(0, 31) + '...'
        }
        return displayTitle
      }
    },

    movie_details: function (item) {
      let params = {_id: item._id}
      this.$http.get('/movie/get', {params: params})
        .then(response => {
          if (response.data.error) {
            this.errorMessage = response.data.error
          } else {
            this.selected_item = response.data.result
          }
        })
        .catch(_ => {
          console.log(params)
        })
      this.movie_details_component_active = true
    },

    watchTrailer: function () {
      this.watch_content = true
    },

    toClipboard: async function (text) {
      try {
        await navigator.clipboard.writeText(text)
        console.log(text)
        this.toast = {
          method: 'success',
          title: 'Copied to clipboard',
          message: 'You can paste the CDN link in VLC to watch the TV-channel'
        }
      } catch ($e) {
        this.toast = {
          method: 'warn',
          title: 'Copied to clipboard failed',
          message: 'Function is only available on ssl connections, copied to console.log() instead'
        }
        console.log(text)
      }
    },

    get_cdn_link: function () {
      try {
        let path = this.selected_item.content_path.directory + '/' + this.selected_item.content_path.filename
        this.$http.post('/cdn', {filename: path})
          .then(async response => {
            if (response.data.error) {
              this.toast = {
                method: 'warn',
                title: 'Failed getting CDN link',
                message: response.data.error
              }
            } else {
              this.content_link = response.data.result
            }
          })
          .catch(_ => {
            console.log('Catch: Failed getting CDN link')
          })
      } catch ($e) {
        alert('Cannot copy')
      }
    }
  }
}
</script>

<style>
.content {
    padding-left: 10px;
}

.b-table {
    text-align: left;
}

.modal-content {
  width: 100%;
  border-radius: 30px;
  margin-top: 125px !important;
  min-height: 700px;
  background-color: white;
}

section {
  margin: 25px;
}

.margin-left {
  margin-left: 5px;
}

.outer-section {
  margin: 125px;
  background: rgba(255,255,255,0.7);
  border-radius: 40px;
}

.img-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  overflow: hidden;
  margin: 0;
  object-fit: fill;
  cursor: pointer;
}

.img-container img {
  display: block;
  margin: 15px;
  width: 192px;
  height: 320px;
  border-radius: 10px;
  background-color: #e2e2e2;
}

.img-container figcaption {
  margin: 0;
  text-align: center;
}

.backdrop_image {
  position: relative;
  width: auto;
  height: 440px;
}

.poster_image {
  top: 140px;
  left: 40px;
  width: 360px;
  height: 540px;
  position: absolute;
  background-color: #dbdbdb;
  border-color: #2c3e50;
  border-radius: 15px;
  border-width: 3px;
}

.flex-container {
    display: flex;
}

.flex-child
{
  flex: 1;
}

.flex-child:first-child {
  flex: 1;
  min-width: 415px;
}

.metadata {
  margin-right: 15px;
  margin-top: 15px;
}

.bm--card-equal-height {
   display: flex;
   flex-direction: column;
   height: 410px
}

.poster-card {
  padding: 0;
  margin: 0;
  background-color: rgb(245, 172, 12);
  border-radius: 10px;
}

.card-header {
  box-shadow: 0 0.125em 0.25em rgb(10 10 10 / 0%);
}

.card-content {
  padding: 0;
  margin-top: 10px;
  text-align: left;
  margin-left: 20px;
}

.content figure {
  margin: 0;
  text-align: left;
  font-size: 14px;
  background-color: black;
}
</style>
