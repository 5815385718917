<template>
  <div id="app">
    <div class="content">
      <navigation v-if="this.$store.state.isLoggedIn"/>
      <router-view class="container"/>
    </div>
  </div>
</template>

<script>

import Navigation from '@/components/Navigation'

export default {
  name: 'App',
  components: { Navigation }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.content {
  flex: 1;
}

.container {
  padding-top: 30px;
}
</style>
