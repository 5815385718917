<template>
  <section>
    <div class="outer-section">
      <section class="inner_padding">
        <div class="panel">
          <div v-if="moviesActive">
            <div class="panel-heading">
              <b-button
                type="is-ghost"
                @click="moviesActive=!moviesActive"><b>Movies</b> / TVShows</b-button>
            </div>
          </div>
          <div v-else>
            <div class="panel-heading">
              <b-button
                type="is-ghost"
                @click="moviesActive=!moviesActive">Movies / <b>TVShows</b></b-button>
            </div>
          </div>

          <div v-if="moviesActive">
            <b-tabs v-model="activeTabMovies">
              <b-tab-item label="Movies generic">
                <b-table
                  :data="moviesGeneric"
                  :columns="moviesGenericColumns"
                  striped/>
              </b-tab-item>

              <b-tab-item label="Movies by genre">
                <b-table
                  :data="moviesByGenre"
                  :columns="moviesByGenreColumns"
                  striped
                  hoverable
                  @click="openMoviesByGenre($event)"/>
              </b-tab-item>

              <b-tab-item label="Movies by year">
                <div>
                  <div class="vertical-hr"><span class="margin-left-right">{{ moviesByYearMax }}</span><span class="vertical_text">number of movies</span></div>
                  <trend
                    :data="moviesByYearAmount"
                    :gradient="['#6fa8dc', '#42b983', '#2c3e50']"
                    auto-draw
                    smooth/>
                  <div class="separator">
                    <b class="margin-left-right">{{ moviesByYearYears[0] }}</b>
                    <div class="line"/>
                    <span class="margin-left-right">Year</span>
                    <div class="line"/>
                    <b class="margin-left-right">{{ moviesByYearYears[1] }}</b>
                  </div>
                </div>
              </b-tab-item>

              <b-tab-item label="Productions by country">
                <MapChart
                  :country-data="moviesByCountry"
                  high-color="#ff0000"
                  low-color="#aaaaaa"
                  country-stroke-color="#909090"
                  default-country-fill-color="#dadada"
                />
              </b-tab-item>

              <b-tab-item label="Movies with missing art">
                <div v-if="moviesMissingArt.length===0">
                  There are no movies with missing art found.
                </div>
                <div v-else>
                  <b-table
                    :data="moviesMissingArt"
                    striped
                    hoverable
                    @click="openSelectedMovie($event)">
                    <b-table-column
                      v-slot="props"
                      field="title"
                      label="Title"
                      sortable>
                      {{ props.row.title }}
                    </b-table-column>

                    <b-table-column
                      v-slot="props"
                      label="Backdrop">
                      <span
                        v-if="props.row['backdrop_path']===false"
                        style="color: red">
                        <b-icon
                          icon="exclamation"
                          pack="fas"
                          size="is-medium"/>
                      </span>
                      <span
                        v-else
                        style="color: greenyellow">
                        <b-icon
                          icon="check"
                          pack="fas"
                          size="is-medium"/>
                      </span>
                    </b-table-column>

                    <b-table-column
                      v-slot="props"
                      label="Poster">
                      <span
                        v-if="props.row['poster_path']===false"
                        style="color: red">
                        <b-icon
                          icon="fa-solid fa-exclamation"
                          pack="fas"
                          size="is-medium"/>
                      </span>
                      <span
                        v-else
                        style="color: greenyellow">
                        <b-icon
                          icon="fa-solid fa-check"
                          pack="fas"
                          size="is-medium"/>
                      </span>
                    </b-table-column>
                  </b-table>
                </div>
              </b-tab-item>
            </b-tabs>
          </div>

          <div v-else>
            <b-tabs v-model="activeTabTVShows">
              <b-tab-item label="TVShows generic">
                <b-table
                  :data="tvshowsGeneric"
                  :columns="tvshowsGenericColumns"
                  striped/>
              </b-tab-item>

              <b-tab-item label="TVShows by genre">
                <b-table
                  :data="tvshowsByGenre"
                  :columns="tvshowsByGenreColumns"
                  striped
                  hoverable
                  @click="openTVShowsByGenre($event)"/>
              </b-tab-item>

              <b-tab-item label="TVShows by year">
                <div>
                  <div class="vertical-hr"><span class="margin-left-right">{{ tvshowsByYearMax }}</span><span class="vertical_text">number of tvshows</span></div>
                  <trend
                    :data="tvshowsByYearAmount"
                    :gradient="['#6fa8dc', '#42b983', '#2c3e50']"
                    auto-draw
                    smooth/>
                  <div class="separator">
                    <b class="margin-left-right">{{ tvshowsByYearYears[0] }}</b>
                    <div class="line"/>
                    <span class="margin-left-right">Year</span>
                    <div class="line"/>
                    <b class="margin-left-right">{{ tvshowsByYearYears[1] }}</b>
                  </div>
                </div>
              </b-tab-item>

              <b-tab-item label="Episodes by year">
                <div>
                  <div class="vertical-hr"><span class="margin-left-right">{{ episodesByYearMax }}</span><span class="vertical_text">number of episodes</span></div>
                  <trend
                    :data="episodesByYearAmount"
                    :gradient="['#6fa8dc', '#42b983', '#2c3e50']"
                    auto-draw
                    smooth/>
                  <div class="separator">
                    <b class="margin-left-right">{{ episodesByYearYears[0] }}</b>
                    <div class="line"/>
                    <span class="margin-left-right">Year</span>
                    <div class="line"/>
                    <b class="margin-left-right">{{ episodesByYearYears[1] }}</b>
                  </div>
                </div>
              </b-tab-item>

              <b-tab-item label="Productions by country">
                <MapChart
                  :country-data="tvshowsByCountry"
                  high-color="#ff0000"
                  low-color="#aaaaaa"
                  country-stroke-color="#909090"
                  default-country-fill-color="#dadada"
                />
              </b-tab-item>

              <b-tab-item label="TVShows with missing art">
                <div v-if="tvshowsMissingArt.length===0">
                  There are no tvshows with missing art found.
                </div>
                <div v-else>
                  <b-table
                    :data="tvshowsMissingArt"
                    striped
                    hoverable
                    @click="openSelectedTVShows($event)">
                    <b-table-column
                      v-slot="props"
                      field="title"
                      label="Title"
                      sortable>
                      {{ props.row.name }}
                    </b-table-column>

                    <b-table-column
                      v-slot="props"
                      label="Backdrop">
                      <span
                        v-if="props.row['backdrop_path']===false"
                        style="color: red">
                        <b-icon
                          icon="fa-solid fa-exclamation"
                          pack="fas"
                          size="is-medium"/>
                      </span>
                      <span
                        v-else
                        style="color: greenyellow">
                        <b-icon
                          icon="fa-solid fa-check"
                          pack="fas"
                          size="is-medium"/>
                      </span>
                    </b-table-column>

                    <b-table-column
                      v-slot="props"
                      label="Poster">
                      <span
                        v-if="props.row['poster_path']===false"
                        style="color: red">
                        <b-icon
                          icon="fa-solid fa-exclamation"
                          pack="fas"
                          size="is-medium"/>
                      </span>
                      <span
                        v-else
                        style="color: greenyellow">
                        <b-icon
                          icon="fa-solid fa-check"
                          pack="fas"
                          size="is-medium"/>
                      </span>
                    </b-table-column>
                  </b-table>
                </div>
              </b-tab-item>

              <b-tab-item label="Episodes with missing art">
                <div v-if="episodesMissingArt.length===0">
                  There are no episodes with missing art found.
                </div>
                <div v-else>
                  <b-table
                    :data="episodesMissingArt"
                    striped
                    hoverable
                    @click="openSelectedTVShows($event)">
                    <b-table-column
                      v-slot="props"
                      field="title"
                      label="Title"
                      sortable>
                      {{ props.row.name }}
                    </b-table-column>

                    <b-table-column
                      v-slot="props"
                      label="Stills">
                      <span style="color: red">
                        <b-icon
                          icon="fa-solid fa-exclamation"
                          pack="fas"
                          size="is-medium"/>
                      </span>
                    </b-table-column>
                  </b-table>
                </div>
              </b-tab-item>
            </b-tabs>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import MapChart from 'vue-map-chart'

export default {
  name: 'Statistics',

  components: { MapChart },

  data () {
    return {
      moviesActive: true,

      activeTabMovies: 0,
      moviesByGenre: [],
      moviesByYearYears: [],
      moviesByYearAmount: [],
      moviesByYearMax: 0,
      moviesByCountry: {},
      moviesMissingArt: [],
      moviesGeneric: [],
      moviesGenericColumns: [
        {
          field: 'moviesRuntime',
          label: 'Combined runtime',
          numeric: false
        },
        {
          field: 'moviesTotal',
          label: 'Total movies',
          numeric: true
        },
        {
          field: 'moviesValidated',
          label: 'Validated movies',
          numeric: true
        }
      ],
      moviesByGenreColumns: [
        {
          field: 'genre',
          label: 'Genre',
          numeric: false,
          sortable: true
        },
        {
          field: 'movies',
          label: 'Number of movies with genre',
          numeric: true,
          sortable: true
        }
      ],
      moviesByYearColumns: [
        {
          field: 'year',
          label: 'Year of publication',
          numeric: false,
          sortable: true
        },
        {
          field: 'movies',
          label: 'Movies in collection',
          numeric: true,
          sortable: true
        }
      ],

      activeTabTVShows: 0,
      tvshowsByGenre: [],
      tvshowsByYearYears: [],
      tvshowsByYearAmount: [],
      tvshowsByYearMax: 0,
      episodesByYearYears: [],
      episodesByYearAmount: [],
      episodesByYearMax: 0,
      tvshowsByCountry: {},
      tvshowsMissingArt: [],
      episodesMissingArt: [],
      tvshowsGeneric: [],
      tvshowsGenericColumns: [
        {
          field: 'episodesRuntime',
          label: 'Combined runtime',
          numeric: false
        },
        {
          field: 'tvshowsTotal',
          label: 'Total tvshows',
          numeric: true
        },
        {
          field: 'episodesTotal',
          label: 'Total episodes',
          numeric: true
        },
        {
          field: 'tvshowsValidated',
          label: 'Validated tvshows',
          numeric: true
        }
      ],
      tvshowsByGenreColumns: [
        {
          field: 'genre',
          label: 'Genre',
          numeric: false,
          sortable: true
        },
        {
          field: 'tvshows',
          label: 'Number of tvshows with genre',
          numeric: true,
          sortable: true
        }
      ],
      tvshowsByYearColumns: [
        {
          field: 'year',
          label: 'Year of publication',
          numeric: false,
          sortable: true
        },
        {
          field: 'tvshows',
          label: 'TVShows in collection',
          numeric: true,
          sortable: true
        }
      ]
    }
  },

  created () {
    this.get_statistics()
  },

  methods: {
    get_statistics: function () {
      this.$http.get('/statistics')
        .then(response => {
          if (response.data.error) {
            this.errorMessage = response.data.error
          } else {
            this.moviesByGenre = response.data.result['movies by genre']
            this.moviesByYearAmount = response.data.result['movies by year']['amount']
            this.moviesByYearMax = response.data.result['movies by year']['max']
            this.moviesByYearYears = response.data.result['movies by year']['years']
            this.moviesByCountry = response.data.result['movies by country']
            this.moviesMissingArt = response.data.result['movies missing art']
            this.moviesGeneric = [
              {
                moviesRuntime: response.data.result['movies runtime'],
                moviesTotal: response.data.result['movies total'],
                moviesValidated: response.data.result['movies validated']
              }
            ]

            this.tvshowsByGenre = response.data.result['tvshows by genre']
            this.tvshowsByYearAmount = response.data.result['tvshows by year']['amount']
            this.tvshowsByYearMax = response.data.result['tvshows by year']['max']
            this.tvshowsByYearYears = response.data.result['tvshows by year']['years']
            this.episodesByYearAmount = response.data.result['episodes by year']['amount']
            this.episodesByYearMax = response.data.result['episodes by year']['max']
            this.episodesByYearYears = response.data.result['episodes by year']['years']
            this.tvshowsByCountry = response.data.result['tvshows by country']
            this.tvshowsMissingArt = response.data.result['tvshows missing art']
            this.episodesMissingArt = response.data.result['episodes missing art']
            this.tvshowsGeneric = [
              {
                episodesRuntime: response.data.result['episodes runtime'],
                tvshowsTotal: response.data.result['tvshows total'],
                episodesTotal: response.data.result['episodes total'],
                tvshowsValidated: response.data.result['tvshows validated']
              }
            ]
          }
        })
        .catch(_ => {
          console.log('Failed fetching statistics')
        })
    },

    openMoviesByGenre: function (event) {
      if (!this.$store.state.isAdmin) {
        this.$router.push({
          name: 'MoviesRegular',
          params: { data: event.genre }
        })
      }
    },

    openSelectedMovie: function (event) {
      if (this.$store.state.isAdmin) {
        this.$router.push({
          name: 'MoviesAdmin',
          params: { data: event }
        })
      }
    },

    openTVShowsByGenre: function (event) {
      if (!this.$store.state.isAdmin) {
        this.$router.push({
          name: 'TVShowsRegular',
          params: { data: event.genre }
        })
      }
    },

    openSelectedTVShows: function (event) {
      if (this.$store.state.isAdmin) {
        this.$router.push({
          name: 'TVShowAdmin',
          params: { data: event }
        })
      }
    }
  }
}
</script>

<style>
.outer-section {
  margin: 125px;
  background: rgba(255,255,255,0.7);
}

.inner_padding {
  margin: 0;
  border-radius: 40px;
  background: white;
}

.panel-heading {
  border-radius: 40px 40px 0 0;
}

.separator{
  display:flex;
  align-items: center;
}

.separator .line{
  height: 3px;
  flex: 1;
  background-color: #000;
}

.separator h2{
  padding: 0 2rem;
}

.margin-left-right {
  margin-left: 5px;
  margin-right: 5px;
}

.vertical-hr {
  margin-top: 0;
  width: 3px;
  height: 70%;
  position: absolute;
  background-color: #000;
}

.vertical_text {
  writing-mode: vertical-rl;
  transform: rotate(0deg);
  white-space: nowrap;
  display: block;
  bottom: 0;
  width: 20px;
  height: 20px;
  margin-top: 0;
  padding: 25px;
}
</style>
