<template>
  <div>
    <section>
      <div>
        <vue-masonry-wall
          :items="filtered_channels"
          :key="filtered_update"
          :options="{width: 300, padding: 12}"
          @append="append_channels(false)">
          <template v-slot="{item}">
            <div class="poster-card-tv bm--card-equal-height-tv">
              <div class="card-header-tv">&nbsp;</div>
              <div
                class="card-image-tv"
                @click="toClipboard(item.channel)">
                <b-image
                  :src="item.icon"
                  :src-fallback="require('@/assets/fallback_channel_image.jpg')"
                  class="img-container-tv"/>
              </div>
              <div class="card-content-tv">
                <span>{{ item.name }}</span>
              </div>
            </div>
          </template>
        </vue-masonry-wall>
      </div>
    </section>
  </div>
</template>

<script>
import ResponsiveImage from '@/components/ImageComponed'

export default {
  components: { ResponsiveImage },

  data () {
    return {
      all_channels: [],
      channels: [],
      filtered_channels: [],
      filtered_update: 0,
      toast: ''
    }
  },

  computed: {},

  watch: {
    toast () {
      if (this.toast.method === 'success') {
        this.$toast.success({
          title: this.toast.title,
          message: this.toast.message,
          color: '#798879'
        })
      } else {
        this.$toast.warn({
          title: this.toast.title,
          message: this.toast.message,
          color: '#cc0000'
        })
      }
    },

    '$store.state.channel_filter' () {
      this.append_channels(true)
      this.filtered_update += 1
    }
  },

  mounted () {},

  beforeDestroy () {},

  created () {
    this.load_all_channels()
  },

  methods: {
    load_all_channels: function () {
      this.$http.get('/tvheadend/get')
        .then(response => {
          if (response.data.error) {
            this.errorMessage = response.data.error
          } else {
            this.all_channels = response.data.result
            if (this.channels.length === 0) {
              let tmp = this.all_channels.slice(this.channels.length, this.channels.length + 40)
              this.channels.push(...tmp)
              this.append_channels(false)
            }
          }
        })
        .catch(e => {
          console.log(e)
        })
    },

    append_channels: function (filter) {
      if (filter === true) {
        let tmp = this.all_channels.slice(0, 40)
        this.channels = JSON.parse(JSON.stringify(tmp))
      } else {
        let tmp = this.all_channels.slice(this.channels.length, this.channels.length + 40)
        this.channels.push(...tmp)
      }

      this.filtered_channels = this.channels.filter(channel => {
        return channel.name.toLowerCase().includes(this.$store.state.channel_filter.toLowerCase())
      })
    },

    toClipboard: async function (channel) {
      try {
        await navigator.clipboard.writeText(channel)
        this.toast = {
          method: 'success',
          title: 'Copied to clipboard',
          message: 'You can paste the CDN link in VLC to watch the TV-channel'
        }
      } catch ($e) {
        this.toast = {
          method: 'warn',
          title: 'Copied to clipboard failed',
          message: 'Function is only available on ssl connections, copied to console.log() instead'
        }
        console.log(channel)
      }
    }
  }
}
</script>

<style>
section {
  margin: 25px;
}

.outer-section {
  margin: 125px;
  background: rgba(255,255,255,0.7);
  border-radius: 40px;
}

.img-container-tv {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  overflow: hidden;
  margin: 0;
  object-fit: fill;
  cursor: pointer;
}

.img-container-tv img {
  display: block;
  margin: 15px;
  width: 200px;
  height: 120px;
  border-radius: 10px;
  background-color: #e2e2e2;
}

.img-container-tv figcaption {
  margin: 0;
  text-align: center;
}

.bm--card-equal-height-tv {
   display: flex;
   flex-direction: column;
   height: 210px
}

.poster-card-tv {
  padding: 0;
  margin: 0;
  background-color: rgb(245, 172, 12);
  border-radius: 10px;
}

.card-header-tv {
  box-shadow: 0 0.125em 0.25em rgb(10 10 10 / 0%);
}

.card-content-tv {
  padding: 0;
  margin-top: 10px;
  text-align: left;
  margin-left: 20px;
}

.content-tv figure {
  margin: 0;
  text-align: left;
  font-size: 14px;
  background-color: black;
}
</style>
