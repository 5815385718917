<template>
  <section>
    <div
      class="card container login-card">
      <img
        alt="Logo"
        class="logo"
        src="@/assets/ankh.png"
        style="opacity: 1.0"
      >

      <b-field
        :type="message ? 'is-danger' : 'is-primary'">
        <b-input
          v-model="username"
          placeholder="username"
          required/>
      </b-field>
      <b-field
        :message="message"
        :type="message ? 'is-danger' : 'is-primary'">
        <b-input
          v-model="password"
          placeholder="Password"
          required
          type="password"
          @keyup.native.enter="login"/>
      </b-field>
      <b-button
        native-type="submit"
        type="is-primary"
        @click="login">
        <b-icon
          icon="sign-in-alt"
          pack="fas"
          size="is-small"/>
        <span>Login</span>
      </b-button>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      username: '',
      password: '',
      message: ''
    }
  },
  mounted () {
    console.log('Vue code mounted in login()')
  },
  methods: {
    login () {
      let data = {
        username: this.username,
        password: this.password
      }
      this.login_post(data)
    },

    login_post (data) {
      this.$http.post('/login', data).then(response => {
        if (response.data.error) {
          this.message = JSON.stringify(response.data.error)
        } else {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + response.data['access_token']
          this.$store.commit('login', {
            isLoggedIn: true,
            username: data.username,
            isAdmin: response.data['is_admin']
          })
          this.$router.push('/').then(_ => console.log('User ' + data.username + ' logged in.'))
        }
      })
    }
  }
}
</script>

<style>
span {
  vertical-align: middle;
}
.card {
  max-width: 500px;
  padding: 20px;
  background: rgba(255,255,255,0.3);
}

.login-card {
  width: 600px;
}

.logo {
  width: 150px;
}

html {
  background: url('~@/assets/background.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
</style>
