import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import router from './router'
import store from './store'
import CxltToastr from 'cxlt-vue2-toastr'
import 'cxlt-vue2-toastr/dist/css/cxlt-vue2-toastr.css'
import 'bootstrap-icons/font/bootstrap-icons'
import 'vue-lazy-youtube-video/dist/style.css'
import LazyYoutubeVideo from 'vue-lazy-youtube-video'
import Trend from 'vuetrend'
import MapChart from 'vue-map-chart'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import VueMasonryWall from 'vue-masonry-wall'
import { Stack, StackItem } from 'vue-stack-grid'
import VueMobileDetection from 'vue-mobile-detection'

Vue.use(FontAwesomeIcon)

const toastrConfigs = {
  position: 'top right',
  showDuration: 1000,
  hideDuration: 1000,
  timeOut: 2500,
  delay: 0,
  progressBar: true,
  showMethod: 'bounceInRight',
  hideMethod: 'fadeOutDown',
  color: '#798879'
}

Vue.use(CxltToastr, toastrConfigs)
Vue.config.productionTip = false
Vue.use(Buefy)
Vue.use(VueAxios, axios)
Vue.use(Trend)
Vue.use(MapChart)
Vue.use(VueMobileDetection)
Vue.component('LazyYoutubeVideo', LazyYoutubeVideo)
Vue.component('Stack', Stack)
Vue.component('StackItem', StackItem)
Vue.component('VueMasonryWall', VueMasonryWall)

Vue.filter('truncate', function (text, length, suffix) {
  if (text.length > length) {
    return text.substring(0, length) + suffix
  } else {
    return text
  }
})

new Vue({
  router,
  store,

  data: function () {
    return {
      ws: undefined,
      data: {},
      toast: ''
    }
  },

  watch: {
    toast () {
      if (this.toast.method === 'success') {
        this.$toast.success({
          title: this.toast.title,
          message: this.toast.message,
          color: '#798879'
        })
      } else {
        this.$toast.warn({
          title: this.toast.title,
          message: this.toast.message,
          color: '#cc0000'
        })
      }
    }
  },

  beforeCreate () {
    this.$http.defaults.baseURL = '/api/v1'
    this.$store.commit('initialiseStore')
    this.$http.defaults.headers.common['Authorization'] = this.$store.state.token
    if (this.$router.currentRoute.path !== '/login') {
      this.$router.push('/login')
    }
  },

  created () {
    this.$http.interceptors.response.use(
      response => response,
      (error) => {
        if (error.response.status === 401) {
          this.$store.commit('logout')
          this.$router.push('/login')
        }
        return Promise.reject(error.response)
      })
    this.$router.beforeEach((to, from, next) => {
      if (to.name !== 'login' && !this.$store.state.isLoggedIn) {
        next({ name: 'login' })
      } else {
        next()
      }
    })

    this.connect()
  },

  methods: {
    connect: function () {
      let websocketURL = ''
      if (location.protocol === 'https:') {
        websocketURL = 'wss://' + location.hostname + '/api/v1/ws'
      } else {
        let port = parseInt(location.port) + 1
        websocketURL = 'ws://' + location.hostname + ':' + port.toString() + '/api/v1/ws'
      }
      this.ws = new WebSocket(websocketURL)

      this.ws.onopen = () => {
        this.ws.send(JSON.stringify({
          // empty message
        }))
      }

      this.ws.onmessage = (event) => {
        this.data = JSON.parse(event.data)
        this.toast = {
          method: this.data.type,
          title: this.data.title,
          message: this.data.message
        }
      }

      this.ws.onclose = function (e) {
        console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason)
        setTimeout(function () {
          this.connect()
        }, 1000)
      }

      this.ws.onerror = (err) => {
        console.error('Socket encountered error: ', err.message, 'Closing socket')
        this.ws.close()
      }
    }
  },

  render: h => h(App)
}).$mount('#app')
