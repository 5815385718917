<template>
  <div>
    <section>
      <div>
        <vue-masonry-wall
          :items="movies"
          :key="filtered_update"
          :options="{width: 300, padding: 12}"
          @append="lazy_load_movies">
          <template v-slot:default="{item}">
            <div class="poster-card bm--card-equal-height">
              <div class="card-header">
                <span v-if="item.validated===true">
                  <b-icon
                    pack="fas"
                    icon="check"
                    type="is-success"
                    size="is-small"
                    class="margin-left"
                    @click="select_item(item)"/>
                </span>
                <span v-else>
                  <span
                    v-if="contains(item)"
                    class="validation">
                    <span
                      @click="select_item(item)">
                      selected for validation
                    </span>
                  </span>
                  <span v-else>
                    <span @click="select_item(item)">
                      <b-icon
                        pack="fas"
                        icon="exclamation-triangle"
                        type="is-danger"
                        size="is-small"
                        class="margin-left"/>
                    </span>
                  </span>
                </span>
              </div>
              <div
                class="card-image"
                @click="movie_details(item)">
                <b-image
                  :src="set_image_url(item['poster_path'], item['poster_http_path'])"
                  lazy
                  class="img-container"/>
              </div>
              <div class="card-content">
                <span>{{ title(item.title) }}</span>
              </div>
            </div>
          </template>
        </vue-masonry-wall>
      </div>

      <b-modal
        v-model="movie_details_component_active"
        scroll="keep"
        width="70%">
        <div v-if="!search_by_title_active">
          <div>
            <div
              id="backdrop"
              class="backdrop_image">
              <ResponsiveImage
                :src="set_image_url(selected_item['backdrop_path'], selected_item['backdrop_http_path'])"
                :key="componentKey"
                skeleton
                class="backdrop_image"/>
              <form
                id="backdrop_image_form"
                :class="['backdrop_image_form backdrop_image_upload_box', drag_over_backdrop ? 'backdrop_dropbox_active' : '']"
                @mouseenter="dragging_enter_backdrop"
                @mouseleave="dragging_leave_backdrop"
                @dragleave="dragging_leave_backdrop"
                @dragenter="dragging_enter_backdrop"
                @drop="handleFileDrop( $event, 'backdrop' )">
                <div ref="backdrop_dropbox">
                  <div class="backdrop_image_upload_text">
                    Drop replacement image
                  </div>
                </div>
              </form>
            </div>

            <div
              id="poster_image"
              class="poster_image">
              <ResponsiveImage
                :src="set_image_url(selected_item['poster_path'], selected_item['poster_http_path'])"
                :key="componentKey"
                max-width="360px"
                max-height="600px"
                border-radius="15px"
                skeleton/>
              <form
                id="poster_image_form"
                :class="['poster_image_form poster_image_upload_box', drag_over_poster ? 'poster_dropbox_active' : '']"
                @mouseenter="dragging_enter_poster"
                @mouseleave="dragging_leave_poster"
                @dragleave="dragging_leave_poster"
                @dragenter="dragging_enter_poster"
                @drop.prevent="handleFileDrop( $event, 'poster' )">
                <div ref="poster_dropbox">
                  <div class="poster_image_upload_text">
                    Drop replacement image
                  </div>
                </div>
              </form>
            </div>

            <div class="flex-container">
              <div class="flex-child">
                &nbsp;
              </div>
              <div
                id="metadata"
                class="flex-child metadata">

                <b-field
                  :label-position="labelPosition"
                  label="Title">
                  <b-input
                    v-model="selected_item.title"
                    maxlength="200"
                    type="text"
                    icon-pack="fas"
                    icon-right="search"
                    icon-right-clickable
                    @icon-right-click="search_by_title()"/>
                </b-field>

                <b-field
                  :label-position="labelPosition"
                  label="Original title">
                  <b-input
                    v-model="selected_item['original_title']"
                    maxlength="200"
                    type="text"/>
                </b-field>

                <b-field
                  :label-position="labelPosition"
                  label="Synopsis">
                  <b-input
                    v-model="selected_item.overview"
                    maxlength="200"
                    type="textarea"/>
                </b-field>

              </div>
              <div
                id="metadata"
                class="flex-child metadata">

                <b-field
                  :label-position="labelPosition"
                  label="Year of release">
                  <b-input
                    v-model="selected_item.year"
                    maxlength="200"
                    type="text"/>
                </b-field>

                <b-field
                  :label-position="labelPosition"
                  label="tmdb-id">
                  <b-input
                    v-model="selected_item.id"
                    maxlength="200"
                    type="text"
                    icon-pack="fas"
                    icon-right="search"
                    icon-right-clickable
                    @icon-right-click="search_by_tmdb_id"/>
                </b-field>

                <b-field
                  :label-position="labelPosition"
                  label="content path">
                  <b-input
                    v-model="movie_file"
                    :readonly="true"
                    maxlength="200"
                    type="text"/>
                </b-field>

                <b-field>
                  <b-button
                    type="is-success is-light"
                    class="margin-left"
                    @click="save_movie_details">
                    Save
                  </b-button>
                  <b-button
                    type="is-danger is-light"
                    class="margin-left"
                    @click="delete_movie_details">
                    Delete
                  </b-button>
                </b-field>

              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <b-field
            grouped
            group-multiline>
            <b-button
              style="margin: 15px"
              @click="search_by_title_active=false">
              back
            </b-button>
          </b-field>
          <b-table
            ref="table"
            :data="search_by_title_results"
            :show-detail-icon="showDetailIcon"
            :opened-detailed="defaultOpenedDetails"
            detail-transition="fade"
            detailed
            hoverable
            selectable
            icon-pack="fas"
            detail-key="id">

            <b-table-column
              v-slot="props"
              label="Title"
              sortable>
              {{ props.row.title }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              label="Year"
              sortable>
              {{ props.row.year }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              label="TMDB Id"
              sortable>
              {{ props.row.id }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              sortable>
              <b-button
                @click="select_movie_by_title(props.row.id)">
                select
              </b-button>
            </b-table-column>

            <template #detail="props">
              <article class="media">
                <figure class="media-left">
                  <p class="image is-64x64">
                    <ResponsiveImage :src="props.row['poster_http_path']"/>
                  </p>
                </figure>
                <div class="media-content">
                  <div class="content">
                    <p>
                      <strong>{{ props.row.title }}</strong>
                      <small>&nbsp;original title: {{ props.row['original_title'] }}</small>
                      <br>
                      {{ props.row.overview }}
                    </p>
                  </div>
                </div>
              </article>
            </template>
          </b-table>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
import VueMasonryWall from 'vue-masonry-wall'
import { Stack, StackItem } from 'vue-stack-grid'
import ResponsiveImage from '@/components/ImageComponed'
import axios from 'axios'

export default {
  components: { ResponsiveImage, VueMasonryWall, Stack, StackItem },

  data () {
    return {
      // Data structures
      movies: [],
      movies_count: 0,
      password: '',
      selected_item: {},
      search_by_title_results: [],
      filtered_update: 0,

      // Variables
      movie_file: '',
      showDetailIcon: true,
      defaultOpenedDetails: [1],
      search_by_title_active: false,
      componentKey: 0,
      drag_over_poster: false, // dragging over the target OR any child element
      drag_over_backdrop: false, // dragging over the target OR any child element
      poster_dropbox: false, // dragging over a child element
      backdrop_dropbox: false, // dragging over a child element
      dragAndDropCapable: false,
      files: [],
      uploadPercentage: 0,
      toast: '',
      movie_details_component_active: false,
      labelPosition: 'on-border'
    }
  },

  computed: {},

  watch: {
    toast () {
      if (this.toast.method === 'success') {
        this.$toast.success({
          title: this.toast.title,
          message: this.toast.message,
          color: '#798879'
        })
      } else {
        this.$toast.warn({
          title: this.toast.title,
          message: this.toast.message,
          color: '#cc0000'
        })
      }
    },
    movie_details_component_active () {
      if (this.movie_details_component_active === false) {
        this.search_by_title_active = false
      }
    },
    selected_item () {
      this.movie_file = this.selected_item['content_path']['directory'] + '/' + this.selected_item['content_path']['filename']
    },

    '$store.state.movie_filter' () {
      this.movies = []
      this.lazy_load_movies()
      this.filtered_update += 1
    }
  },

  mounted () {},

  beforeDestroy () {},

  created () {
    if (this.$route.params.data !== undefined) {
      this.movie_details(this.$route.params.data)
    }
    this.lazy_load_movies()
  },

  methods: {
    search_by_title: function () {
      let params = {'title': this.selected_item.title}
      this.$http.get('/scraper/movie/by_title', {params: params})
        .then(response => {
          if (response.data.error) {
            this.toast = {
              method: 'warn',
              title: 'Search by title failed',
              message: response.data.error
            }
          } else {
            if (response.data.result.length === 0) {
              this.toast = {
                method: 'warn',
                title: 'Searching by title',
                message: 'No results found for ' + this.selected_item.title
              }
            } else {
              this.toast = {
                method: 'success',
                title: 'Searching by title',
                message: 'Found ' + response.data.result.length + ' titles'
              }
              this.search_by_title_results = response.data.result
              this.search_by_title_active = true
            }
          }
        })
        .catch(_ => {
          console.log('Catch: Search failed!')
        })
    },

    select_movie_by_title: function (tmdbId) {
      this.$http.post('/scraper/movie/by_id', {tmdb_id: tmdbId})
        .then(response => {
          if (response.data.error) {
            this.toast = {
              method: 'warn',
              title: 'Search by tmdb-id failed',
              message: response.data.error
            }
          } else {
            let _id = this.selected_item['_id']
            let contentPath = this.selected_item['content_path']
            this.selected_item = response.data.result
            this.selected_item['_id'] = _id
            this.selected_item['content_path'] = contentPath
            this.toast = {
              method: 'success',
              title: 'Searching by TMDB-id',
              message: this.selected_item.id
            }
            this.search_by_title_active = false
          }
        })
        .catch(_ => {
          console.log('Catch: Search failed!')
        })
    },

    search_by_tmdb_id: function () {
      this.$http.post('/scraper/movie/by_id', {tmdb_id: parseInt(this.selected_item.id)})
        .then(response => {
          if (response.data.error) {
            this.toast = {
              method: 'warn',
              title: 'Search by tmdb-id failed',
              message: response.data.error
            }
          } else {
            let _id = this.selected_item['_id']
            let contentPath = this.selected_item['content_path']
            this.selected_item = response.data.result
            this.selected_item['_id'] = _id
            this.selected_item['content_path'] = contentPath
            this.toast = {
              method: 'success',
              title: 'Searching by TMDB-id',
              message: this.selected_item.id
            }
          }
        })
        .catch(_ => {
          console.log('Catch: Search failed!')
        })
    },

    set_image_url: function (identifier, original) {
      if (identifier === undefined) {
        return original
      }
      return '/api/v1/image/get?image_id=' + identifier
    },

    lazy_load_movies: function () {
      let params = {match: this.$store.state.movie_filter}
      if (this.movies.length !== 0) {
        params.page_id = this.movies[this.movies.length - 1]._id
      }
      this.$http.get('/movies/get', {params: params})
        .then(response => {
          if (response.data.error) {
            this.errorMessage = response.data.error
          } else {
            this.movies = this.movies.concat(response.data.result)
            this.movies = [...new Map(this.movies.map(item => [item['_id'], item])).values()]
            this.movies_count = response.data.count
          }
        })
        .catch(_ => {
          console.log(params)
        })
    },

    select_item: function (item) {
      if (this.contains(item)) {
        this.$store.state.selected.movies = this.$store.state.selected.movies.filter(_ => _._id !== item._id)
        console.log('Un-selected ' + item.title + ' for validation approval')
      } else {
        this.$store.state.selected.movies.push(item)
        console.log('Selected ' + item.title + ' for validation approval')
      }
    },

    contains: function (item) {
      for (let i = 0; i < this.$store.state.selected.movies.length; i++) {
        if (this.$store.state.selected.movies[i]._id === item._id) {
          return true
        }
      }
      return false
    },

    title: function (title) {
      if (title !== undefined) {
        let displayTitle = title.replace(/\n/g, ' ')
        if (displayTitle.length > 31) {
          return displayTitle.substring(0, 31) + '...'
        }
        return displayTitle
      }
    },

    movie_details: function (item) {
      let params = {_id: item._id}
      this.$http.get('/movie/get', {params: params})
        .then(response => {
          if (response.data.error) {
            this.errorMessage = response.data.error
          } else {
            this.selected_item = response.data.result
          }
        })
        .catch(_ => {
          console.log(params)
        })
      this.movie_details_component_active = true
    },

    save_movie_details: function () {
      this.selected_item.year = parseInt(this.selected_item.year)
      this.$http.patch('/movie/patch', this.selected_item)
        .then(response => {
          if (response.data.error) {
            this.toast = {
              method: 'warn',
              title: 'Update failed',
              message: response.data.error
            }
          } else {
            for (let i = 0; i < this.movies.length; i++) {
              if (this.movies[i]._id === this.selected_item._id) {
                this.movies[i] = JSON.parse(JSON.stringify(this.selected_item))
              }
            }
            this.toast = {
              method: 'success',
              title: 'Updated movie',
              message: this.selected_item.title
            }
            this.selected_item = {}
          }
        })
        .catch(_ => {
          console.log('Catch: update failed!')
        })
      this.movie_details_component_active = false
    },

    delete_movie_details: function () {
      let data = {id: this.selected_item._id}
      this.$http.delete('/movie/delete', {data: data})
        .then(response => {
          if (response.data.error) {
            this.toast = {
              method: 'warn',
              title: 'Delete failed',
              message: response.data.error
            }
          } else {
            this.movies = this.movies.filter(_ => _._id !== this.selected_item._id)
            this.toast = {
              method: 'success',
              title: 'Deleted movie',
              message: this.selected_item.title
            }
            this.selected_item = {}
          }
        })
        .catch(_ => {
          console.log(data)
        })
      this.movie_details_component_active = false
    },

    dragging_enter_poster: function (event) {
      if (event.target === this.$refs.poster_dropbox) this.poster_dropbox = true
      else this.drag_over_poster = true
      this.bindEvents()
    },

    dragging_leave_poster: function (event) {
      if (event.target === this.$refs.poster_dropbox) this.poster_dropbox = false
      else this.drag_over_poster = false
      this.bindEvents()
    },

    dragging_enter_backdrop: function (event) {
      if (event.target === this.$refs.backdrop_dropbox) this.backdrop_dropbox = true
      else this.drag_over_backdrop = true
      this.bindEvents()
    },

    dragging_leave_backdrop: function (event) {
      if (event.target === this.$refs.backdrop_dropbox) this.backdrop_dropbox = false
      else this.drag_over_backdrop = false
      this.bindEvents()
    },

    bindEvents: function () {
      // Enable drag drop event-handlers
      this.dragAndDropCapable = this.determineDragAndDropCapable()
      if (this.dragAndDropCapable) {
        ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(function (evt) {
          document.getElementById('poster_image_form').addEventListener(evt, function (e) {
            e.preventDefault()
            e.stopPropagation()
          }, false)
          document.getElementById('backdrop_image_form').addEventListener(evt, function (e) {
            e.preventDefault()
            e.stopPropagation()
          }, false)
        })
      }
    },

    handleFileDrop (event, type) {
      console.log('Uploading ' + type + ' image')
      for (let i = 0; i < event.dataTransfer.files.length; i++) {
        this.files.push(event.dataTransfer.files[i])
      }
      this.submitFiles(type)
    },

    determineDragAndDropCapable () {
      let div = document.createElement('div')
      return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window
    },

    submitFiles (type) {
      let formData = new FormData()
      formData.append('type', type)
      formData.append('movie_id', this.selected_item['_id'])
      if (type === 'poster') {
        formData.append('image_id', this.selected_item['poster_path'])
        formData.append('path', 'poster_path')
      } else {
        formData.append('image_id', this.selected_item['backdrop_path'])
        formData.append('path', 'backdrop_path')
      }
      formData.append('data', this.files[0])

      axios.post('/movie/image/post',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
          }.bind(this)
        }
      ).then((response) => {
        this.selected_item = response.data.result
        for (let i = 0; i < this.movies.length; i++) {
          if (this.movies[i]._id === this.selected_item._id) {
            this.movies[i] = JSON.parse(JSON.stringify(this.selected_item))
          }
        }
        this.componentKey += 1
        this.files = []
        console.log('SUCCESS!!')
      })
        .catch((response) => {
          this.files = []
          console.log('FAILURE!!')
        })
    }
  }
}
</script>

<style>
.content {
  padding-left: 10px;
}

.b-table {
  text-align: left;
}

.modal-content {
  width: 100%;
  border-radius: 30px;
  margin-top: 125px !important;
  min-height: 700px;
  background-color: white;
}

section {
  margin: 25px;
}

.margin-left {
  margin-left: 5px;
}

.outer-section {
  margin: 125px;
  background: rgba(255,255,255,0.7);
  border-radius: 40px;
}

.img-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  overflow: hidden;
  margin: 0;
  object-fit: fill;
  cursor: pointer;
}

.img-container img {
  display: block;
  margin: 15px;
  width: 192px;
  height: 320px;
  border-radius: 10px;
  background-color: #e2e2e2;
}

.img-container figcaption {
  margin: 0;
  text-align: center;
}

.backdrop_image {
  position: relative;
  width: auto;
  height: 440px;
}

.backdrop_image_form {
  position: relative;
  width: auto;
  height: 440px;
}

.backdrop_image_upload_box {
  position:absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 440px;
}

.backdrop_image_upload_box:hover,
.backdrop_image_upload_box.backdrop_dropbox_active {
  visibility: visible;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.8);
  color: cyan;
  font-size: large;
}

.backdrop_image_upload_text {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 65%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  font-size: large;
  padding: 16px 32px;
  color: cyan;
}

.backdrop_image_upload_box:hover .backdrop_image_upload_text {
  opacity: 1;
}

.backdrop_image_upload_box.backdrop_dropbox_active .backdrop_image_upload_text {
  opacity: 1;
}

.poster_image {
  top: 140px;
  left: 40px;
  width: 360px;
  height: 540px;
  position: absolute;
  background-color: #dbdbdb;
  border-color: #2c3e50;
  border-radius: 15px;
  border-width: 3px;
}

.poster_image_form {
  top: 0;
  left: 0;
  width: 360px;
  height: 540px;
  position: absolute;
}

.poster_image_upload_box {
  position:absolute;
  top:0;
  left:0;
  width:360px;
  height:540px;
}

.poster_image_upload_box:hover,
.poster_image_upload_box.poster_dropbox_active {
  visibility: visible;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.8);
  color: cyan;
  font-size: large;
}

.poster_image_upload_text {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  font-size: large;
  padding: 16px 32px;
  color: cyan;
}

.poster_image_upload_box:hover .poster_image_upload_text {
  opacity: 1;
}

.poster_image_upload_box.poster_dropbox_active .poster_image_upload_text {
  opacity: 1;
}

.flex-container {
    display: flex;
}

.flex-child
{
  flex: 1;
}

.flex-child:first-child {
  flex: 1;
  min-width: 415px;
}

.metadata {
  margin-right: 15px;
  margin-top: 15px;
}

.bm--card-equal-height {
   display: flex;
   flex-direction: column;
   height: 410px
}

.poster-card {
  padding: 0;
  margin: 0;
  background-color: rgb(245, 172, 12);
  border-radius: 10px;
}

.card-header {
  box-shadow: 0 0.125em 0.25em rgb(10 10 10 / 0%);
}

.card-content {
  padding: 0;
  margin-top: 10px;
  text-align: left;
  margin-left: 20px;
}

.content figure {
  margin: 0;
  text-align: left;
  font-size: 14px;
  background-color: black;
}

.validation {
  margin-left: 20px;
}

.table {
  border-width: 0;
}
.intable {
  border-width: 0;
}
.table, .intable {
  display: table;
  width: 100%;
  height: 100%;
}
.cell {
  display: table-cell;
}
.row {
  display: table-row;
}
.cell {
  border-width: 0;
  width: 25%
}
.table, .intable, .cell {
    border-style: solid;
}
</style>
